import { useBubbleText } from './useBubbleText.hook';
import { useStartNewGameButton } from './useStartNewGameButton.hook';

export const useNewGameData = () => {
  const button = useStartNewGameButton();
  const bubbleText = useBubbleText();

  return {
    button,
    bubbleText,
  };
};

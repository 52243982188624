"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationCard = void 0;
const NotificationCard_1 = require("./NotificationCard");
const NotificationCardBoard_1 = require("./NotificationCardBoard");
const NotificationCardContent_1 = require("./NotificationCardContent");
const NotificationCardContentGameInfo_1 = require("./NotificationCardContentGameInfo");
const NotificationCardHeader_1 = require("./NotificationCardHeader");
const NotificationCardHeaderContent_1 = require("./NotificationCardHeaderContent");
const NotificationCardHeaderDivider_1 = require("./NotificationCardHeaderDivider");
const NotificationCardComposition = NotificationCard_1.NotificationCard;
exports.NotificationCard = NotificationCardComposition;
NotificationCardComposition.Header = NotificationCardHeader_1.NotificationCardHeader;
NotificationCardComposition.HeaderContent = NotificationCardHeaderContent_1.NotificationCardHeaderContent;
NotificationCardComposition.Divider = NotificationCardHeaderDivider_1.NotificationCardHeaderDivider;
NotificationCardComposition.Content = NotificationCardContent_1.NotificationCardContent;
NotificationCardComposition.ContentGameInfo = NotificationCardContentGameInfo_1.NotificationCardContentGameInfo;
NotificationCardComposition.Board = NotificationCardBoard_1.NotificationCardBoard;

import { ITournamentGameTourResult } from '@types';
import { BaseService } from './_base.service';
import { httpInstance } from '.';

export class TournamentGameService extends BaseService {
  /**
   * Осуществляет запрос на получение результата в турнирной игре
   * @param {string} boardId - id доски
   */
  getTournamentGameResult(boardId: string) {
    return this.ajax.get<ITournamentGameTourResult>(
      `online/tournament/gaming/${boardId}/result/`
    );
  }
}

export const tournamentGameService = new TournamentGameService({
  instance: httpInstance,
});

import React, {
  Dispatch,
  PropsWithChildren,
  useContext,
  useReducer,
} from 'react';

export interface ICustomHeaderContextState {
  challengesPopupOpen: boolean;
}

export enum customHeaderActionType {
  SET_CHALLENGES_POPUP_OPEN = 'SET_CHALLENGES_POPUP_OPEN',
}

export interface ICustomHeaderContextActionMessage {
  type: customHeaderActionType;
}

export interface ISetChallengesPopupOpen
  extends ICustomHeaderContextActionMessage {
  type: customHeaderActionType.SET_CHALLENGES_POPUP_OPEN;
  payload: boolean;
}

export type ICustomHeaderContextAction = ISetChallengesPopupOpen;

export interface ICustomHeaderContextProps {
  state: ICustomHeaderContextState;
  dispatch: Dispatch<ICustomHeaderContextAction>;
}

const customHeaderContextReducer = (
  state: ICustomHeaderContextState,
  { type, payload }: ICustomHeaderContextAction
) => {
  switch (type) {
    case customHeaderActionType.SET_CHALLENGES_POPUP_OPEN: {
      return {
        ...state,
        challengesPopupOpen: payload,
      };
    }
    default:
      return state;
  }
};

const createInitState = (): ICustomHeaderContextState => ({
  challengesPopupOpen: false,
});

export const CustomHeaderContext = React.createContext(
  {} as ICustomHeaderContextProps
);

export const CustomHeaderContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(
    customHeaderContextReducer,
    createInitState()
  );

  return (
    <CustomHeaderContext.Provider value={{ state, dispatch }}>
      {children}
    </CustomHeaderContext.Provider>
  );
};

export const useCustomHeaderContext = () => {
  return useContext(CustomHeaderContext);
};

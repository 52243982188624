import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { getMyColor } from '@utils/_getMyColor';
import { Chessgun, chessSide } from 'chessgun/core';
import { useEffect, useMemo, useState } from 'react';
import { getOpponentColor, getPlayerData } from 'shared/helpers/_game';
import { useTimeForBothPlayers } from './_useTimeForBothPlayers.hook';
import { getChessgunMovesFromTurns } from '@utils/_ng_helpers';

export const useActiveGameCardData = ({
  gameData,
  playerUid,
  engine,
}: {
  gameData: ILobbyContextGame;
  playerUid: string | null;
  engine: Chessgun;
}) => {
  const [playerTurn, setPlayerTurn] = useState<chessSide>(chessSide.WHITE);

  const state = gameData.state;
  const turns = state?.playingPh?.turns;

  const myColor = getMyColor(playerUid, state);

  const whitePlayer = useMemo(() => {
    const whitePlayerData = gameData.state?.cfg?.publicExtra?.white_player;
    if (!whitePlayerData) return null;

    return whitePlayerData;
  }, [gameData.state?.cfg?.publicExtra?.white_player]);

  const blackPlayer = useMemo(() => {
    const blackPlayerData = gameData.state?.cfg?.publicExtra?.black_player;
    if (!blackPlayerData) return null;

    return blackPlayerData;
  }, [gameData.state?.cfg?.publicExtra?.black_player]);

  const moves = useMemo(() => {
    if (!turns?.length) return [];
    return getChessgunMovesFromTurns(turns);
  }, [turns]);

  const { opponentTime, playerTime } = useTimeForBothPlayers({
    myColor,

    playerTurn,
    state,
  });

  const player = useMemo(() => {
    if (whitePlayer && blackPlayer) {
      return getPlayerData({
        playerType: 'me',
        myColor,
        whiteData: whitePlayer,
        blackData: blackPlayer,
      });
    }

    return null;
  }, [blackPlayer, myColor, whitePlayer]);

  const opponent = useMemo(() => {
    if (whitePlayer && blackPlayer) {
      return getPlayerData({
        playerType: 'opponent',
        myColor,
        whiteData: whitePlayer,
        blackData: blackPlayer,
      });
    }

    return null;
  }, [blackPlayer, myColor, whitePlayer]);

  const isOpponentTurn = playerTurn === getOpponentColor(myColor);
  const isPlayerTurn = playerTurn === myColor;

  useEffect(() => {
    const unsub = engine?.on('turn', (turn) => {
      setPlayerTurn(turn);
    });
    return unsub;
  }, [engine]);

  return {
    myColor,
    opponentTime,
    playerTime,
    player,
    opponent,
    isOpponentTurn,
    isPlayerTurn,
    moves,
  };
};

import React, { useMemo } from "react";
import { getFile } from "../../core/stringBoard/_getFile";
import { getRank } from "../../core/stringBoard/_getRank";
import { chessSide, FILES, RANKS, SQUARES } from "../../core/_constants";
import { getArrowPointPosition, getFileAndRankIndexesFromPosition } from "../../ui/_helpers";
import { eLastMoveTheme } from "../../ui/_settings.types";
const arrowColor = {
    [chessSide.WHITE]: "white",
    [chessSide.BLACK]: "black",
};
export const LastMove = ({ theme, lan, chessboardSize, turn }) => {
    if (theme === eLastMoveTheme.HIGHLIGHT) {
        return React.createElement(LastMoveHighLight, { lan: lan });
    }
    if (theme === eLastMoveTheme.ARROW) {
        return React.createElement(LastMoveArrow, { lan: lan, turn: turn, chessboardSize: chessboardSize });
    }
    return null;
};
const LastMoveHighLight = ({ lan }) => {
    const fromTo = useMemo(() => {
        const from = SQUARES[lan.substring(0, 2)];
        const to = SQUARES[lan.substring(2, 4)];
        return {
            from: getFileAndRankIndexesFromPosition(from),
            to: getFileAndRankIndexesFromPosition(to),
        };
    }, [lan]);
    const square = ({ file, rank }) => (React.createElement("div", { "data-cg-type": "last-move-square", style: {
            position: "absolute",
            top: 0,
            left: 0,
            width: "calc(100% / 8)",
            height: "calc(100% / 8)",
            transform: `translate(${file * 100}%, ${rank * 100}%)`,
        } }));
    return (React.createElement(React.Fragment, null,
        square(fromTo.from),
        square(fromTo.to)));
};
const LastMoveArrow = ({ lan, turn, chessboardSize }) => {
    const lastMovePolylinePoints = useMemo(() => {
        const from = SQUARES[lan.split("").splice(0, 2).join("")];
        const to = SQUARES[lan.split("").splice(2, 2).join("")];
        const squareSize = chessboardSize / 8;
        const startPos = {
            x: FILES.indexOf(getFile(from)) * squareSize + squareSize / 2,
            y: (7 - RANKS.indexOf(getRank(from))) * squareSize + squareSize / 2,
        };
        const endPos = {
            x: FILES.indexOf(getFile(to)) * squareSize + squareSize / 2,
            y: (7 - RANKS.indexOf(getRank(to))) * squareSize + squareSize / 2,
        };
        const s = getArrowPointPosition({ chessboardSize, pos: startPos });
        const e = getArrowPointPosition({
            chessboardSize,
            startPos: s,
            pos: endPos,
        });
        return `${s.x},${s.y} ${e.x},${e.y}`;
    }, [lan, chessboardSize]);
    return (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", style: { width: "100%", height: "100%" } },
        React.createElement("marker", { id: `stream-board-arrow-${chessSide.WHITE}`, viewBox: "0 0 20 20", refX: "10", refY: "10", markerUnits: "strokeWidth", markerWidth: "5", markerHeight: "5", orient: "auto" },
            React.createElement("polyline", { points: "0,0 10,10 0,20", strokeWidth: "4", stroke: "white", fill: "none" })),
        React.createElement("marker", { id: `stream-board-arrow-${chessSide.BLACK}`, viewBox: "0 0 20 20", refX: "10", refY: "10", markerUnits: "strokeWidth", markerWidth: "5", markerHeight: "5", orient: "auto" },
            React.createElement("polyline", { points: "0,0 10,10 0,20", strokeWidth: "4", stroke: "black", fill: "none" })),
        lastMovePolylinePoints && (React.createElement("polyline", { points: lastMovePolylinePoints, stroke: arrowColor[turn], strokeWidth: 1, markerEnd: `url(#stream-board-arrow-${turn})` }))));
};

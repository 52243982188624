import { useCallback, useMemo } from 'react';

import { HeaderActiveGames } from '@chessarena/components/lib/layout/Header/types';
import { ActiveGamesPopupFragment } from './_fragments/ActiveGamesPopupFragment/ActiveGamesPopupFragment';
import { useActiveGamesContext } from '@store/context/active-games/_context';
import { useApplicationContext } from '@application';

export const useCustomHeaderActiveGames = () => {
  const { localization: l } = useApplicationContext();

  const renderActiveGamesPopup = useCallback(
    (closePopup: () => void) => (
      <ActiveGamesPopupFragment closePopup={closePopup} />
    ),
    []
  );
  const {
    computed: { activeGames },
  } = useActiveGamesContext();

  const activeGamesData: HeaderActiveGames = useMemo(
    () => ({
      title: l?.active_games?.active_games,
      renderActiveGamesPopup,
      count: activeGames.length,
    }),
    [l, activeGames.length, renderActiveGamesPopup]
  );

  return activeGamesData;
};

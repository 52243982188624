import { PlayerInfo } from '@chessarena/components/lib/game/PlayerInfo';
import { usePlayerInfoData } from '@utils/hooks/activeGames/_usePlayerInfoData.hook';

import { FC, memo } from 'react';
import { INotificationCardPlayerInfoProps } from './types';
import { useMediaBreakpoint } from '@chessarena/components/lib/utils/useMediaBreakpoint';

const useHasAvatar = () => {
  const breakpoint = useMediaBreakpoint({
    moreThanPhone: (width, { tablet }) => width >= tablet,
    phone: (width, { tablet }) => width < tablet,
  });

  return breakpoint === 'moreThanPhone';
};

export const NotificationCardPlayerInfo: FC<INotificationCardPlayerInfoProps> =
  memo(function NotificationCardPlayerInfo({ player }) {
    const hasAvatar = useHasAvatar();
    const playerInfo = usePlayerInfoData(player);

    if (!playerInfo) {
      return null;
    }

    const {
      avatarSrc,
      playerAge,
      playerCountry,
      playerName,
      playerRating,
      playerTitle,
      premium,
    } = playerInfo;

    const PlayerInfoComponent = (
      <PlayerInfo
        avatarSrc={avatarSrc}
        playerName={playerName}
        playerAge={playerAge}
        premium={premium}
        playerCountry={playerCountry}
        playerRating={playerRating}
        playerTitle={playerTitle}
        isOnline={true}
        size={'s'}
        hasAvatar={hasAvatar}
      />
    );

    return <>{PlayerInfoComponent}</>;
  });

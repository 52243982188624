import { useApplicationContext } from '@application';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { capitalizeString } from 'shared/helpers/_common';
import { getTournamentType } from 'shared/helpers/_tournament';
import { useActiveGameTournamentData } from './_useTournamentData.hook';

export const useSwissData = (gameData: ILobbyContextGame) => {
  const { localization: l } = useApplicationContext();
  const tournamentData = useActiveGameTournamentData(gameData);

  if (!tournamentData || !gameData || !tournamentData.tournament_kind) {
    return null;
  }

  const tournamentTypeText = capitalizeString(
    getTournamentType(tournamentData.tournament_kind)
  );

  const roundLocalization = l?.game_page?.round?.long ?? 'Round';
  const tournamentRound = tournamentData.tour_number ?? 0;
  const roundText = `${roundLocalization} ${tournamentRound}`;

  return {
    roundText,
    tournamentTypeText,
  };
};

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useLobbyContext } from '../_lobby.context';

export const useCurrentGameData = () => {
  const router = useRouter();
  const id = router.query.id as string;

  const {
    state: { gamesData },
  } = useLobbyContext();

  const boardData = useMemo(() => {
    const data = gamesData[id];

    return data ? data : null;
  }, [gamesData, id]);

  return boardData;
};

"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$TournamentInfo = exports.$RatingCountryAge = exports.$PlayerInfoBottom = exports.$PlayerInfoName = exports.$PlayerInfoAvatar = exports.$PlayerInfo = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const constants_1 = require("../../common/constants");
function getTheme(theme) {
    return {
        height: { s: '34px', m: '36px', l: '40px' },
        gap: { s: '2px 8px', m: '4px 8px', l: '8px' },
        color: theme.semantic_color.game_ui.content.main,
        ratingCountryAge: {
            color: theme.semantic_color.text.secondary,
        },
        tournamentInfo: {
            color: theme.semantic_color.text.primary,
            backgroundColor: theme.component_color.tournament.result.bg,
            borderColor: theme.component_color.tournament.result.border,
        },
    };
}
exports.$PlayerInfo = styled_components_1.default.div `
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr;
  color: ${({ theme }) => getTheme(theme).color};
  gap: ${({ theme, $size }) => getTheme(theme).gap[$size]};
  height: ${({ theme, $size }) => getTheme(theme).height[$size]};

  ${({ $hasAvatar }) => {
    if ($hasAvatar) {
        return (0, styled_components_1.css) `
        grid-template-areas:
          'avatar player-name'
          'avatar player-bottom';
      `;
    }
    return (0, styled_components_1.css) `
      grid-template-areas:
        'player-name'
        'player-bottom';
    `;
}}
`;
exports.$PlayerInfoAvatar = styled_components_1.default.div `
  grid-area: avatar;
  align-self: center;
`;
exports.$PlayerInfoName = styled_components_1.default.div `
  grid-area: player-name;
`;
exports.$PlayerInfoBottom = styled_components_1.default.div `
  grid-area: player-bottom;

  display: flex;
  align-items: center;
  gap: 4px;
`;
exports.$RatingCountryAge = styled_components_1.default.p `
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
  margin: 0;

  font-family: ${constants_1.fonts.primary};
  font-size: 12px;
  line-height: 12px;
  font-weight: ${constants_1.fontWeights.light};
  color: ${(props) => getTheme(props.theme).ratingCountryAge.color};
`;
exports.$TournamentInfo = styled_components_1.default.span `
  margin: 0;
  padding: 0 4px 2px;

  font-family: ${constants_1.fonts.primary};
  font-size: 12px;
  line-height: 12px;
  font-weight: ${constants_1.fontWeights.light};
  color: ${({ theme }) => theme.colors.text.white};
  color: ${(props) => getTheme(props.theme).tournamentInfo.color};
  background: ${(props) => getTheme(props.theme).tournamentInfo.backgroundColor};
  border: 1px solid ${(props) => getTheme(props.theme).tournamentInfo.borderColor};
  border-radius: 2px;
`;

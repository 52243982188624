import React, { useMemo } from "react";
import { getTitleRanks } from "./helpers/getTitleRanks";
import { getTitleFiles } from "./helpers/getTitleFiles";
export const FILES = ["a", "b", "c", "d", "e", "f", "g", "h"];
export const RANKS = ["1", "2", "3", "4", "5", "6", "7", "8"];
export const Titles = ({ chessboardSize, rotate }) => {
    const ratio = useMemo(() => chessboardSize / 8, [chessboardSize]);
    const fontSize = useMemo(() => {
        const $fontSize = chessboardSize / 60;
        if ($fontSize > 16) {
            return 16;
        }
        else if ($fontSize < 4) {
            return 4;
        }
        else {
            return $fontSize;
        }
    }, [chessboardSize]);
    const rankItems = useMemo(() => getTitleRanks({ ranks: RANKS, rotate, ratio, fontSize }), [rotate, ratio, fontSize]);
    const fileItems = useMemo(() => getTitleFiles({ files: FILES, rotate, ratio, fontSize }), [rotate, ratio, fontSize]);
    return (React.createElement("div", { className: "cg-titles" },
        React.createElement("div", { className: rotate ? "rotated" : "" },
            React.createElement("div", { className: "col" }, rankItems.map((item) => (React.createElement("span", { key: item.id, style: item.style }, item.id)))),
            React.createElement("div", { className: "row" }, fileItems.map((item) => (React.createElement("span", { key: item.id, style: item.style }, item.id)))))));
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const react_dom_1 = require("react-dom");
const style_1 = require("./style");
const Drawer = ({ nodeRef, opened, onClose, children }) => {
    const [mounted, setMounted] = (0, react_1.useState)(false);
    const innerNode = (0, react_1.useRef)(null);
    const bodyNode = typeof window === 'undefined' ? null : document.querySelector('body');
    (0, react_1.useEffect)(() => {
        if (!bodyNode)
            return;
        if (opened)
            bodyNode.style.overflow = 'hidden';
        else
            bodyNode.style.overflow = '';
        return () => {
            if (bodyNode)
                bodyNode.style.overflow = '';
        };
    }, [opened, bodyNode]); // eslint-disable-line
    const handleClick = (0, react_1.useCallback)((e) => {
        var _a, _b;
        if (opened &&
            !(((_a = innerNode.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) || ((_b = nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.current) === null || _b === void 0 ? void 0 : _b.contains(e.target)))) {
            onClose();
        }
    }, [opened, onClose, nodeRef]);
    (0, react_1.useEffect)(() => {
        setMounted(true);
        document.addEventListener('click', handleClick, true);
        document.addEventListener('touchend', handleClick, true);
        return () => {
            setMounted(false);
            document.removeEventListener('click', handleClick, true);
            document.removeEventListener('touchend', handleClick, true);
        };
    }, [handleClick]);
    if (!mounted)
        return null;
    return (0, react_dom_1.createPortal)((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)(style_1.$DrawerFade, { "data-component": "DrawerFade", "$opened": opened }), (0, jsx_runtime_1.jsx)(style_1.$Drawer, { "data-component": "Drawer", "$opened": opened, children: (0, jsx_runtime_1.jsx)(style_1.$DrawerInner, { ref: innerNode, children: opened && children }) })] }), bodyNode);
};
exports.default = Drawer;

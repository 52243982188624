import { chessSide, DEFAULT_POSITION } from "../../../core";
import { parseFen } from "../../../core/fen/_parseFen";
export const createPieces = (fen = DEFAULT_POSITION) => {
    const { figures, checkmate: { check, checkmate, kingIndex }, } = parseFen(fen);
    return figures.map((parsedFig) => {
        return {
            id: "id" + Math.random().toString(16).slice(2),
            position: parsedFig.position,
            bgTag: parsedFig.type + (parsedFig.color === chessSide.WHITE ? "w" : "b"),
            type: parsedFig.type,
            color: parsedFig.color,
            check: check && kingIndex === parsedFig.position,
            checkmate: checkmate && kingIndex === parsedFig.position,
        };
    });
};

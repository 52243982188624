"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PopupContext = void 0;
const react_1 = require("react");
exports.PopupContext = (0, react_1.createContext)({
    opened: false,
    opener: null,
    setOpenerNode: (node) => {
        console.log(node); /* eslint-disable-line */
    },
    onClose: () => { },
    onOpen: () => { },
    onToggle: () => { },
    closingOnOutClick: false,
    closingOnEscPress: false,
    closingOnOpenerClick: false,
    closingOnMouseLeave: false,
});

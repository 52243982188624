import { ActiveGamesPopup } from '@chessarena/components/lib/active-games/ActiveGamesPopup';
import { FC, PropsWithChildren, memo } from 'react';
import { ActiveGamesList } from './fragments/ActiveGamesList';
import { Header } from './fragments/Header';

interface IActiveGamesPopupFragmentProps {
  closePopup: () => void;
}

export const ActiveGamesPopupFragment: FC<
  PropsWithChildren<IActiveGamesPopupFragmentProps>
> = memo(function ActiveGamesPopupFragment({ closePopup }) {
  return (
    <ActiveGamesPopup>
      <Header closePopup={closePopup} />

      <ActiveGamesList closePopup={closePopup} />
    </ActiveGamesPopup>
  );
});

import { GoToGame } from '@chessarena/components/lib/active-games/GoToGame';
import { NotificationCard as Card } from '@chessarena/components/lib/active-games/NotificationCard';
import { WaitingForOpponentNextMove } from '@chessarena/components/lib/active-games/WaitingForOpponentNextMove';
import { BoardClickArea } from '@chessarena/components/lib/general/BoardClickArea';
import { StyledLink } from '@components/atoms/link/StyledLink';
import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { useChessboardSettingsForBoard } from '@utils/hooks/_useChessboardSettingsForBoard.hook';
import { useProjectBreakpoints } from '@utils/hooks/_useProjectBreakpoints.hook';
import { useActiveGameCardData } from '@utils/hooks/activeGames/_useActiveGameCardData.hook';
import { CGStreamBoard } from 'chessgun/react';
import { FC, memo } from 'react';
import { myColorIsWhite } from 'shared/helpers/_game';
import { CardGameTimer } from '../CardGameTimer/CardGameTimer';
import { NotificationCardHeader } from './NotificationCardHeader';
import { NotificationCardPlayerInfo } from './NotificationCardPlayerInfo';
import { INotificationCardProps } from './types';

const BoardSizeMap = {
  wideScreen: 160,
  largeDesktop: 160,
  desktop: 160,
  tablet: 140,
  phone: 120,
};

const useBoardSize = () => {
  const breakpoint = useProjectBreakpoints();

  return BoardSizeMap[breakpoint];
};

export const NotificationCard: FC<INotificationCardProps> = memo(
  function NotificationCard({ engine, gameData, playerUid, boardId, onClick }) {
    const { localization: l } = useApplicationContext();

    const { myColor, isPlayerTurn, playerTime, opponent, moves } =
      useActiveGameCardData({ engine, gameData, playerUid });
    const boardSettings = useChessboardSettingsForBoard();
    const boardSize = useBoardSize();

    if (!gameData) {
      return null;
    }

    return (
      <StyledLink href={`${routesConstants.GAME}/${boardId}`} onClick={onClick}>
        <Card>
          <Card.Header>
            <NotificationCardHeader gameData={gameData} />
          </Card.Header>

          <Card.Content>
            <NotificationCardPlayerInfo player={opponent} />

            <Card.ContentGameInfo>
              {isPlayerTurn ? (
                <GoToGame
                  title={l?.active_games?.go_to_game ?? 'Go to game!'}
                  description={
                    l?.active_games?.your_turn_to_make_move ??
                    'Your turn to make a move'
                  }
                />
              ) : (
                <WaitingForOpponentNextMove
                  text={
                    l?.active_games?.waiting_for_the_opponent ??
                    'Waiting for the opponent'
                  }
                />
              )}

              <CardGameTimer
                milliseconds={playerTime}
                isCurrentTurn={isPlayerTurn}
              />
            </Card.ContentGameInfo>
          </Card.Content>

          <Card.Board>
            <CGStreamBoard
              {...boardSettings}
              chessboardSize={boardSize}
              history={moves}
              rotate={!myColorIsWhite(myColor)}
            />

            <BoardClickArea />
          </Card.Board>
        </Card>
      </StyledLink>
    );
  }
);

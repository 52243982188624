import React, {
  createContext,
  memo,
  useCallback,
  useContext,
  useReducer,
} from 'react';

export interface IZenContextState {
  isZenModeActive: boolean;
}

export interface IZenContextProps {
  state: IZenContextState;
  actions: {
    setZenModeActive: (active: boolean) => void;
    toggleZenMode: () => void;
  };
}

export const ZenContext = createContext({} as IZenContextProps);
export enum eZenContextActionType {
  SET_ZEN_MODE_ACTIVE,
}

const reducer = (
  state: IZenContextState,
  { key, payload }: { key: eZenContextActionType; payload: boolean }
) => {
  switch (key) {
    case eZenContextActionType.SET_ZEN_MODE_ACTIVE:
      return {
        ...state,
        isZenModeActive: payload,
      };
  }
  return state;
};

export const ZenContextProvider: React.FC<React.PropsWithChildren> = memo(
  function ZenContextProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, { isZenModeActive: false });

    const setZenModeActive = useCallback((active: boolean) => {
      dispatch({
        key: eZenContextActionType.SET_ZEN_MODE_ACTIVE,
        payload: active,
      });
    }, []);

    const toggleZenMode = useCallback(() => {
      dispatch({
        key: eZenContextActionType.SET_ZEN_MODE_ACTIVE,
        payload: !state.isZenModeActive,
      });
    }, [state.isZenModeActive]);

    return (
      <ZenContext.Provider
        value={{ state, actions: { setZenModeActive, toggleZenMode } }}
      >
        {children}
      </ZenContext.Provider>
    );
  }
);

export const useZenContext = () => useContext(ZenContext);

import { useMemo } from 'react';

import { useApplicationContext } from '@application';

export const useCustomHeaderThemes = () => {
  const { theme, switchTheme, localization: l } = useApplicationContext();

  const themes = useMemo(
    () => ({
      title:
        theme === 'dark'
          ? l.header.account.themes.titles.dark
          : l.header.account.themes.titles.light,
      onClick: switchTheme,
    }),
    [theme, l.header.account.themes.titles, switchTheme]
  );

  return {
    themes,
  };
};

import { IGameState } from '@store/context/_types';
import {
  getGameStatus,
  getPlayersMsLeft,
  getPlayersPreMoveMs,
} from '@utils/_ng_helpers';
import { chessSide } from 'chessgun/core';
import { useMemo } from 'react';
import { getPlayerData } from 'shared/helpers/_game';
import { usePlayerTime } from '../_usePlayerTime.hook';
import { usePreMoveTime } from '../_usePreMoveTime.hook';

/**
 * Отдает время для противника и для игрока
 */
export const useTimeForBothPlayers = ({
  state,
  playerTurn,
  myColor,
}: {
  state: IGameState | undefined;
  playerTurn: chessSide;
  myColor: chessSide;
}) => {
  const publicExtra = state?.cfg?.publicExtra;

  const { whitePreMoveMsLeft, blackPreMoveMsLeft } = useMemo(() => {
    return getPlayersPreMoveMs({
      currentMoveData: state?.playingPh?.currTurn,
      playingPhCfg: state?.cfg?.playingPhCfg,
    });
  }, [state?.cfg?.playingPhCfg, state?.playingPh?.currTurn]);

  const timeControlInitMs = useMemo(() => {
    const ms = state?.cfg?.playingPhCfg.wTc.init;

    return ms ? ms : null;
  }, [state?.cfg?.playingPhCfg.wTc.init]);

  const preMoveWhiteMs = usePreMoveTime({
    playerColor: chessSide.WHITE,
    playerTurn,
    whitePreMoveMsLeft,
    blackPreMoveMsLeft,
  });

  const preMoveBlackMs = usePreMoveTime({
    playerColor: chessSide.BLACK,
    playerTurn,
    whitePreMoveMsLeft,
    blackPreMoveMsLeft,
  });

  const { whiteMsLeft, blackMsLeft } = useMemo(() => {
    return getPlayersMsLeft(state);
  }, [state]);

  const awaitingMoveWhite = preMoveWhiteMs > 0;
  const awaitingMoveBlack = preMoveBlackMs > 0;

  const gameStatus = useMemo(
    () =>
      getGameStatus(
        { result: publicExtra?.result ?? null },
        state?.currPh,
        state?.donePh
      ),
    [publicExtra?.result, state?.currPh, state?.donePh]
  );

  const { milliseconds: whiteTimeMs } = usePlayerTime({
    msLeft: whiteMsLeft,
    gameStatus,
    playerTurn,
    gameEnded: false,
    playerColor: chessSide.WHITE,
    awaitingMove: awaitingMoveWhite,
    timeControlMs: timeControlInitMs,
  });

  const { milliseconds: blackTimeMs } = usePlayerTime({
    msLeft: blackMsLeft,
    gameStatus,
    playerTurn,
    gameEnded: false,
    playerColor: chessSide.BLACK,
    awaitingMove: awaitingMoveBlack,
    timeControlMs: timeControlInitMs,
  });

  return {
    opponentTime: getPlayerData({
      playerType: 'opponent',
      myColor,
      whiteData: whiteTimeMs,
      blackData: blackTimeMs,
    }),
    playerTime: getPlayerData({
      playerType: 'me',
      myColor,
      whiteData: whiteTimeMs,
      blackData: blackTimeMs,
    }),
  };
};

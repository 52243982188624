import { useCallback, useEffect, useMemo } from 'react';

import { ePopupPaths } from '@constants';
import { useAuthStore, userDataActions } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';
import { openPopup } from '@utils/_router';
import { useApplicationContext } from '@application';
import { getChellengesItems } from '@components/molecules/menus/CustomHeader/_utils/_getChellengesItems';
import { ChallengesBoardFragment } from '@components/molecules/menus/CustomHeader/_fragments/ChallengesBoardFragment';
import { ChallengesItemClickEventParams } from '@chessarena/components/lib/layout/Challenges/types';
import { useAlertPopup } from '@utils/hooks/_useAlertPopup.hook';

export const useCustomHeaderChallenges = () => {
  const { localization: l } = useApplicationContext();

  const token = useAuthStore('token');
  const userChallenges = useUserDataStore('challenges');
  const userChallengesRequest = useUserDataStore('challenges_request');

  useEffect(() => {
    if (token) {
      userDataActions.getChallengesList();
    }
  }, [token]);

  const {
    isAlertPopupShown,
    alertPopupRef,
    onClose,

    alertText,
    showAlertPopup,
  } = useAlertPopup();

  const items = useMemo(() => {
    return getChellengesItems({
      items: userChallenges?.incoming,
      l,
      renderBoard: ChallengesBoardFragment,
      userChallengesRequest,
    });
  }, [userChallenges, userChallengesRequest, l]);

  const onAcceptClick = useCallback(
    (id: number) =>
      userDataActions.acceptChallenge(id, {
        alert: showAlertPopup,
      }),
    [showAlertPopup]
  );

  const onDeclineClick = useCallback(
    (id: number) => userDataActions.declineChallenge(id),
    []
  );

  const onItemClick = useCallback(
    ({ id, actionId }: ChallengesItemClickEventParams) => {
      switch (actionId) {
        case 'accept':
          return onAcceptClick(id);
        case 'decline':
          return onDeclineClick(id);
        default:
          return null;
      }
    },
    [onAcceptClick, onDeclineClick]
  );

  const challenges = useMemo(
    () => ({
      title: l?.challenges?.title,
      hidden:
        !userChallenges?.incoming?.length && !userChallenges?.outcoming?.length,
      outcoming: !!userChallenges?.outcoming?.length,
      count:
        userChallenges?.incoming?.length || userChallenges?.outcoming?.length,
      captions: { empty: l.challenges.empty },
      items,
      onClick: () => {
        if (!!userChallenges?.outcoming?.length)
          openPopup(`?popup=${ePopupPaths.CHALLENGE}`);
      },
      onItemClick,
    }),
    [l, items, userChallenges, onItemClick]
  );

  return {
    challenges,
    alert: {
      isAlertPopupShown,
      alertPopupRef,
      onClose,
      text: alertText,
    },
  };
};

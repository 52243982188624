"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const utils_1 = require("./utils");
const style_1 = require("./style");
const GameTimer = (0, react_1.memo)(function GameTimer(props) {
    const { milliseconds, isCurrentPlayerTurn, isDanger } = props;
    const time = (0, utils_1.msToGameTime)(milliseconds);
    return ((0, jsx_runtime_1.jsx)(style_1.$GameTimer, { "$currentPlayerTurn": isCurrentPlayerTurn, "$danger": isDanger, children: time }));
});
exports.default = GameTimer;

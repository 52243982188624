"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.$StyledLink = void 0;
const styled_components_1 = __importStar(require("styled-components"));
function getTheme(theme) {
    return {
        primary: {
            colors: {
                normal: theme.semantic_color.text.primary,
                hover: theme.component_color.button.link.primary.content.hover,
                active: theme.component_color.button.link.primary.content.hover,
                disabled: theme.component_color.button.link.primary.content.disabled,
            },
        },
        secondary: {
            colors: {
                normal: theme.component_color.button.link.primary.content.default,
                hover: theme.component_color.button.link.primary.content.hover,
                active: theme.component_color.button.link.primary.content.hover,
                disabled: theme.component_color.button.link.primary.content.disabled,
            },
        },
    };
}
function getColor(props, hover) {
    if (props.$disabled)
        return getTheme(props.theme)[props.$type].colors.disabled;
    if (props.$active)
        return getTheme(props.theme)[props.$type].colors.active;
    if (hover)
        return getTheme(props.theme)[props.$type].colors.hover;
    return getTheme(props.theme)[props.$type].colors.normal;
}
function getUnderline(props, hover) {
    if (!props.$underline || props.$disabled)
        return '';
    const color = getColor(props, hover);
    return `background: linear-gradient(to left, ${color}, ${color} 100%) repeat-x 0 100%; background-size: 100% 0.5px;`;
}
exports.$StyledLink = styled_components_1.default.span `
  position: relative;
  box-sizing: border-box;
  display: ${(props) => (props.$block ? 'block' : 'inline')};
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  ${({ $overflow }) => $overflow &&
    (0, styled_components_1.css) `
      overflow: ${$overflow};
    `}

  & > a,
  & > span {
    display: ${(props) => (props.$block ? 'block' : 'inline')};
    color: ${(props) => getColor(props)};
    transition: color 200ms ease-out;
    text-decoration: none;
    ${(props) => getUnderline(props)}

    &:hover {
      color: ${(props) => getColor(props, true)};
      ${(props) => getUnderline(props, true)}
    }
  }
`;

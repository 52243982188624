import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import { getTournamentType } from 'shared/helpers/_tournament';
import { useActiveGameTournamentData } from './_useTournamentData.hook';

export const useTournamentType = (gameData: ILobbyContextGame) => {
  const tournamentData = useActiveGameTournamentData(gameData);
  if (!tournamentData?.tournament_kind) return null;

  return getTournamentType(tournamentData.tournament_kind);
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Bubble = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const useMediaBreakpoint_1 = require("../../../utils/useMediaBreakpoint");
const style_1 = require("../style");
const svgBySizeMap = {
    wideScreen: {
        svgProps: {
            width: '424',
            height: '364',
            viewBox: '0 0 424 364',
            fill: 'none',
        },
        content: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("g", { clipPath: "url(#clip0_994_295563)", children: (0, jsx_runtime_1.jsx)("path", { d: "M103.856 92.5984L135.744 155.336L60.3708 147.334L79.8417 181.652L1.35765 194.642L57.1975 228.9L35.2603 259.627L88.7639 280.307L75.1429 318.139L65.1327 308.781L48.7507 345.648L63.5566 327.29L80.0045 338.904L111.596 296.339L112.855 335.493L141.645 316.042L155.995 350.291L177.276 309.879L189.894 363.159L235.477 289.071L323.857 306.087L290.428 267.44L409.139 267.127L325.074 233.283L421.201 180.864L281.298 163.647L346.878 94.3973L251.951 134.899L257.219 0.881818L202.736 113.407L182.645 72.9588L162.633 141.716L103.856 92.5984Z" }) }), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", { id: "clip0_994_295563", children: (0, jsx_runtime_1.jsx)("rect", { width: "424", height: "364", fill: "white" }) }) })] })),
    },
    desktop: {
        svgProps: {
            width: '380',
            height: '364',
            viewBox: '0 0 380 364',
            fill: 'none',
        },
        content: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("g", { clipPath: "url(#clip0_994_295561)", children: (0, jsx_runtime_1.jsx)("path", { d: "M93.0786 92.5984L121.657 155.336L54.1059 147.334L71.5563 181.652L1.21678 194.642L51.2619 228.9L31.6012 259.627L79.5526 280.307L67.345 318.139L58.3737 308.781L43.6917 345.648L56.9611 327.29L71.7021 338.904L100.015 296.339L101.144 335.493L126.946 316.042L139.806 350.291L158.88 309.879L170.188 363.159L211.041 289.071L290.249 306.087L260.289 267.44L366.681 267.127L291.34 233.283L377.492 180.864L252.107 163.647L310.881 94.3973L225.805 134.899L230.527 0.88183L181.697 113.407L163.691 72.9588L145.756 141.716L93.0786 92.5984Z" }) }), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", { id: "clip0_994_295561", children: (0, jsx_runtime_1.jsx)("rect", { width: "380", height: "364", fill: "white" }) }) })] })),
    },
    tablet: {
        svgProps: {
            width: '348',
            height: '364',
            viewBox: '0 0 348 364',
            fill: 'none',
        },
        content: ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [(0, jsx_runtime_1.jsx)("g", { clipPath: "url(#clip0_994_295560)", children: (0, jsx_runtime_1.jsx)("path", { d: "M85.2404 92.5984L111.412 155.336L49.5496 147.334L65.5305 181.652L1.11431 194.642L46.9451 228.9L28.9401 259.627L72.8534 280.307L61.6739 318.139L53.458 308.781L40.0124 345.648L52.1644 327.29L65.6641 338.904L91.5931 296.339L92.6264 335.493L116.256 316.042L128.033 350.291L145.5 309.879L155.856 363.159L193.269 289.071L265.807 306.087L238.37 267.44L335.803 267.127L266.806 233.283L345.703 180.864L230.877 163.647L284.702 94.3974L206.79 134.899L211.114 0.881843L166.397 113.407L149.907 72.9588L133.482 141.716L85.2404 92.5984Z" }) }), (0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("clipPath", { id: "clip0_994_295560", children: (0, jsx_runtime_1.jsx)("rect", { width: "348", height: "364", fill: "white" }) }) })] })),
    },
    mobile: {
        svgProps: {
            width: '316',
            height: '277',
            viewBox: '0 0 336 288',
            fill: 'none',
        },
        content: ((0, jsx_runtime_1.jsx)("path", { d: "M82.3011 73.2645L107.571 122.903L47.841 116.572L63.2708 143.725L1.07591 154.002L45.3263 181.108L27.9422 205.419L70.3412 221.781L59.5472 251.714L51.6146 244.31L38.6327 273.48L50.3656 258.954L63.3998 268.144L88.4347 234.466L89.4324 265.445L112.247 250.055L123.618 277.153L140.483 245.178L150.482 287.335L186.604 228.715L256.641 242.179L230.15 211.601L324.223 211.353L257.606 184.575L333.782 143.101L222.915 129.479L274.885 74.6878L199.659 106.733L203.834 0.69754L160.659 89.7281L144.738 57.7255L128.879 112.127L82.3011 73.2645Z" })),
    },
};
exports.Bubble = (0, react_1.memo)(function Bubble({ text }) {
    const media = (0, useMediaBreakpoint_1.useMediaBreakpoint)({
        wideScreen: (width, { wideScreen }) => width >= wideScreen,
        desktop: (width, { desktop, wideScreen }) => width >= desktop && width < wideScreen,
        tablet: (width, { tablet, desktop }) => width >= tablet && width < desktop,
        mobile: (width, { tablet }) => width < tablet,
    });
    const { content, svgProps } = svgBySizeMap[media];
    return ((0, jsx_runtime_1.jsxs)(style_1.$Bubble, { "$width": svgProps.width, "$height": svgProps.height, children: [(0, jsx_runtime_1.jsx)(style_1.$BubbleBg, Object.assign({}, svgProps, { children: content })), (0, jsx_runtime_1.jsx)(style_1.$BubbleText, { children: text })] }));
});

import { useEffect, useState } from 'react';

/**
 * Хук для получения ширины и высоты окна браузера
 * @params {number} defaultWidth - Ширина по умолчанию
 * @params {number} defaultHeight - Высота по умолчанию
 * @returns {number[]} - ширина и высота
 */
export function useWindowScales(defaultWidth = 0, defaultHeight = 0) {
  const [width, setWidth] = useState(defaultWidth);
  const [height, setHeight] = useState(defaultHeight);

  useEffect(() => {
    setWidth(document.documentElement.clientWidth);
    setHeight(document.documentElement.clientHeight);
    function resize() {
      setWidth(document.documentElement.clientWidth);
      setHeight(document.documentElement.clientHeight);
    }

    window.addEventListener('resize', resize);

    return () => {
      window.removeEventListener('resize', resize);
    };
  }, []);

  return [width, height];
}

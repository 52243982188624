import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

dayjs.extend(duration);
dayjs.extend(utc);

const ONE_HOUR_MS = 3600000;

export const isLessThanTenMinutesUntilTournamentEnd = (
  tournamentEndTime: string
) => {
  const currentTime = dayjs();
  const tournamentEndDate = dayjs(tournamentEndTime);
  const tenMinutesBeforeTournamentEnd = tournamentEndDate.subtract(
    10,
    'minutes'
  );

  return currentTime.isAfter(tenMinutesBeforeTournamentEnd);
};

export const getTimeToEnd = (ms: number): string => {
  const milliseconds = dayjs.utc(dayjs.duration(ms, 'ms').asMilliseconds());

  // больше чем час - показываем время в формате мин.сек
  if (ms >= ONE_HOUR_MS) {
    const sumMinutes = Math.floor(dayjs.duration(ms, 'ms').asMinutes());

    return `${sumMinutes}:${milliseconds.format('ss')}`;
  }

  return milliseconds.format('m:ss');
};

import React, { useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { routesConstants } from '@constants';
import Logo from '@chessarena/components/lib/layout/Logo';
import { useApplicationContext } from '@application';
import { useNGPageContext } from '@pages/game/[id]/context';
import { headerHome, headerMenu } from './_constants';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

export const useCustomHeaderMenu = () => {
  const router = useRouter();

  const { localization: l } = useApplicationContext();

  const ngPageContext = useNGPageContext();

  const home = useMemo(
    () => ({
      ...headerHome,
      title: <Logo />,
      link: (
        <Link href={headerHome.link} prefetch={false}>
          <Logo />
        </Link>
      ),
    }),
    []
  );

  const items = useMemo(
    () =>
      headerMenu.items.map((item) => {
        let active = false;

        if (!!router.asPath.match(new RegExp(`^${item.link}`))) active = true;

        if (item.id === 'community') {
          if (
            !!router.asPath.match(new RegExp(`^${routesConstants.COMMUNITY}`))
          )
            active = true;
          if (router.asPath.match(new RegExp(`^${routesConstants.PLAYERS}`)))
            active = false;
        }

        if (
          !!router.asPath.match(
            new RegExp(`^${routesConstants.BROADCAST_BOARD}`)
          ) &&
          item.id === 'broadcasts'
        )
          active = true;

        if (
          !!router.asPath.match(new RegExp(`^${routesConstants.GAME}`)) &&
          item.id === 'tournaments'
        )
          active = !!ngPageContext?.computed?.tournamentId;

        if (
          !!router.asPath.match(new RegExp(`^${routesConstants.PUZZLES}`)) &&
          item.id === 'learn'
        )
          active = true;

        if (
          !!router.asPath.match(new RegExp(`^${routesConstants.ARMAGEDDON}`)) &&
          item.id === 'armageddon'
        )
          active = true;

        return {
          ...item,
          title: l?.header.menu[item.id],
          link: item.link && (
            <Link href={item.link} prefetch={false}>
              {l?.header.menu[item.id]}
            </Link>
          ),
          active,
          current: item.link === router.asPath,
        };
      }),
    [l, router.asPath, ngPageContext?.computed?.tournamentId]
  );

  const onItemClick = (id: string) => {
    if (id === 'lobby') gtmPush({ event: GTM_EVENTS.LOBBY_CLICK });
  };

  const menu = useMemo(
    () => ({
      ...headerMenu,
      items,
      onItemClick,
    }),
    [items]
  );

  return {
    home,
    menu,
  };
};

"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("./style");
const Link = (props) => {
    const { children, id, href, block = false, underline, active, disabled, type = 'primary', onClick, overflow } = props, rest = __rest(props, ["children", "id", "href", "block", "underline", "active", "disabled", "type", "onClick", "overflow"]);
    return ((0, jsx_runtime_1.jsxs)(style_1.$StyledLink, { "data-component": "Link", id: id, onClick: onClick, "$block": block, "$underline": underline, "$disabled": disabled, "$active": active, "$type": type, "$overflow": overflow, children: [href && !disabled && ((0, jsx_runtime_1.jsx)("a", Object.assign({ href: href, rel: "noreferrer" }, rest, { children: children }))), href && disabled && (0, jsx_runtime_1.jsx)("span", { children: children }), !href && children] }));
};
exports.default = Link;

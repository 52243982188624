import { useEffect, useMemo } from 'react';
import { chessSide } from 'chessgun/core';
import { BoardType, GameStatus } from 'shared/types';

import { SoundType } from '@types';
import { useNGPageContext } from '../_ngPage.context';
import { useIsPlayer } from './_useIsPlayer.hook';
import { soundsActions } from '@store/storeshed';
import { usePlayerTime } from '@utils/hooks/_usePlayerTime.hook';
import { usePreMoveTime } from '@utils/hooks/_usePreMoveTime.hook';
import { useGameTimers } from '../../hooks/_useGameTimers.hook';

export const useUserTimer = ({
  playerType,
  playerColor,
}: {
  playerType: 'me' | 'opponent';
  playerColor?: chessSide;
}) => {
  const {
    state: {
      topPlayerWarn,
      bottomPlayerWarn,
      animationTime,
      gameEnded,
      soundPlayed,
      isGameDataLoaded,
    },
    computed: {
      playerTurn,
      gameStatus,
      boardData,
      currentGame,
      whitePreMoveMsLeft,
      blackPreMoveMsLeft,
    },
    actions: {
      setAnimationTime,
      setTopPlayerWarn,
      setBottomPlayerWarn,
      setSoundPlayed,
    },
  } = useNGPageContext();

  const isPlayer = useIsPlayer();

  const msLeft = useGameTimers(playerColor);

  const preMoveMs = usePreMoveTime({
    playerColor,
    playerTurn,
    whitePreMoveMsLeft,
    blackPreMoveMsLeft,
  });

  const firstMoveCfg = useMemo(() => {
    const cfg = currentGame?.state?.cfg?.playingPhCfg?.wFirstMoveCfg;

    return cfg
      ? {
          warnMs: cfg.warns[0],
          abortMs: cfg.abort,
        }
      : null;
  }, [currentGame?.state?.cfg]);

  const timeControlMs = useMemo(() => {
    const ms = currentGame?.state?.cfg?.playingPhCfg.wTc.init;

    return ms ? ms : null;
  }, [currentGame?.state?.cfg]);

  const boardType = useMemo(() => {
    return boardData?.time_control.board_type;
  }, [boardData?.time_control.board_type]);

  const setPlayerWarn = useMemo(() => {
    return playerType === 'me' ? setBottomPlayerWarn : setTopPlayerWarn;
  }, [playerType, setBottomPlayerWarn, setTopPlayerWarn]);

  const playerWarn = useMemo(() => {
    return playerType === 'me' ? bottomPlayerWarn : topPlayerWarn;
  }, [bottomPlayerWarn, playerType, topPlayerWarn]);

  const { milliseconds, paused } = usePlayerTime({
    msLeft,
    gameStatus,
    playerTurn,
    gameEnded,
    playerColor,
    awaitingMove: preMoveMs > 0,
    timeControlMs,
    isGameDataLoaded,
  });

  const isBelowWarnTime = useMemo(() => milliseconds < 15000, [milliseconds]);

  useEffect(() => {
    if (!playerColor) return;

    const currTurn = currentGame?.state?.playingPh?.currTurn;
    if (!playerColor || gameStatus !== GameStatus.STARTED || !currTurn) {
      return;
    }

    if (gameEnded) {
      setPlayerWarn(false);
      return;
    }

    if (isBelowWarnTime && playerTurn === playerColor) {
      setPlayerWarn(true);
      return;
    }

    // Process first move warn
    if (
      milliseconds &&
      timeControlMs &&
      firstMoveCfg &&
      playerTurn === playerColor
    ) {
      const msSpent = timeControlMs - milliseconds;

      if (msSpent >= firstMoveCfg.warnMs) {
        if (playerColor === chessSide.WHITE && currTurn.absNum === 1) {
          setPlayerWarn(true);

          return;
        }

        if (playerColor === chessSide.BLACK && currTurn.absNum === 2) {
          setPlayerWarn(true);

          return;
        }
      }

      if (
        (currTurn.absNum > 2 || msSpent < firstMoveCfg.warnMs) &&
        playerWarn
      ) {
        setPlayerWarn(false);
      }
    }
  }, [timeControlMs, milliseconds, gameEnded, playerTurn, playerColor]);

  useEffect(() => {
    if (!playerColor) return;

    const isBelowThirtySeconds = milliseconds < 30000;

    if (
      !isBelowThirtySeconds &&
      (boardType === BoardType.BULLET || gameEnded) &&
      animationTime !== 100
    ) {
      setAnimationTime(100);
    }

    // с 30 сек изменияем анимацию хода
    if (
      isBelowThirtySeconds &&
      playerType === 'me' &&
      !gameEnded &&
      animationTime !== 0 &&
      !paused
    ) {
      setAnimationTime(0);
    }
  }, [milliseconds, gameEnded, paused, playerColor]);

  useEffect(() => {
    if (!bottomPlayerWarn || playerType !== 'me' || !milliseconds || !isPlayer)
      return;
    if (isBelowWarnTime && soundPlayed) return;

    soundsActions.playSound(SoundType.zeitnot);

    if (isBelowWarnTime) {
      setSoundPlayed(true);
    }
  }, [bottomPlayerWarn, isBelowWarnTime, isPlayer]);

  return milliseconds;
};

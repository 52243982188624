import { GameRatingMode } from 'shared/types';

/**
 * Возвращает локализованный текст названия рейтинга
 * @param rating
 * @param ratingsLocalization
 * @returns
 */
export const getRatingTypeText = (
  rating: GameRatingMode | null | undefined,
  ratingsLocalization: Record<GameRatingMode, string>
) => {
  if (!rating) return '';

  return ratingsLocalization[rating];
};

import { useMemo } from 'react';
import { GameType } from '@types';
import { useMediaBreakpoint } from '@chessarena/components/lib/utils/useMediaBreakpoint';
import { useWindowScales } from '@utils/hooks/_useWindowScales.hook';

const NAVBAR_HEIGHT = 40;
const MOBILE_NAVBAR_HEIGHT = 80;

const WIDE_SCREEN_SIZES = {
  OPPONENT_HEIGHT: 88,
  PLAYER_HEIGHT: 96,
  MIN_SIDE_WIDTH: 244,
  HORIZONTAL_GAP: 24,
  TOURNAMENT_BADGE_HEIGHT: 56,
} as const;

const DESKTOP_SCREEN_SIZES = {
  OPPONENT_HEIGHT: 66,
  PLAYER_HEIGHT: 70,
  MIN_SIDE_WIDTH: 244,
  HORIZONTAL_GAP: 16,
  TOURNAMENT_BADGE_HEIGHT: 36,
} as const;

const TABLET_SCREEN_SIZES = {
  OPPONENT_HEIGHT: 50,
  BOTTOM_PART_HEIGHT: 150,
  HORIZONTAL_PADDINGS: 40,
  TOURNAMENT_BADGE_HEIGHT: 48,
} as const;

const MOBILE_SCREEN_SIZES = {
  BADGE_HEIGHT: 40, // time control mobile + gap
  OPPONENT_HEIGHT: 52,
  BOTTOM_PART_HEIGHT: 164,
  HORIZONTAL_PADDINGS: 10,
  TOURNAMENT_BADGE_HEIGHT: 57,
} as const;

type CalculationProps = {
  windowWidth: number;
  windowHeight: number;

  isTournament: boolean;
};

type BoardSizes = {
  boardSize: number;
  boardTopMargin?: number;
};

const getWideScreenBoardSize = ({
  windowWidth,
  windowHeight,

  isTournament,
}: CalculationProps): BoardSizes => {
  const {
    OPPONENT_HEIGHT,
    PLAYER_HEIGHT,
    MIN_SIDE_WIDTH,
    HORIZONTAL_GAP,
    TOURNAMENT_BADGE_HEIGHT,
  } = WIDE_SCREEN_SIZES;
  const maxBoardWidth = windowWidth - 2 * MIN_SIDE_WIDTH - 2 * HORIZONTAL_GAP;
  const maxBoardHeight =
    windowHeight -
    (isTournament ? TOURNAMENT_BADGE_HEIGHT : 0) -
    NAVBAR_HEIGHT -
    OPPONENT_HEIGHT -
    PLAYER_HEIGHT;

  const boardSize = Math.min(maxBoardHeight, maxBoardWidth);
  const roundedBoardSize = boardSize - (boardSize % 8);

  return { boardSize: roundedBoardSize };
};

const getDesktopBoardSize = ({
  windowWidth,
  windowHeight,

  isTournament,
}: CalculationProps): BoardSizes => {
  const {
    OPPONENT_HEIGHT,
    PLAYER_HEIGHT,
    MIN_SIDE_WIDTH,
    HORIZONTAL_GAP,
    TOURNAMENT_BADGE_HEIGHT,
  } = DESKTOP_SCREEN_SIZES;

  const maxBoardWidth = windowWidth - 2 * MIN_SIDE_WIDTH - 2 * HORIZONTAL_GAP;
  const maxBoardHeight =
    windowHeight -
    (isTournament ? TOURNAMENT_BADGE_HEIGHT : 0) -
    NAVBAR_HEIGHT -
    OPPONENT_HEIGHT -
    PLAYER_HEIGHT;

  const boardSize = Math.min(maxBoardHeight, maxBoardWidth);
  const roundedBoardSize = boardSize - (boardSize % 8);

  return { boardSize: roundedBoardSize };
};

const getTabletBoardSize = ({
  windowWidth,
  windowHeight,

  isTournament,
}: CalculationProps): BoardSizes => {
  const {
    BOTTOM_PART_HEIGHT,
    HORIZONTAL_PADDINGS,
    OPPONENT_HEIGHT,
    TOURNAMENT_BADGE_HEIGHT,
  } = TABLET_SCREEN_SIZES;

  const maxBoardHeight =
    windowHeight -
    (isTournament ? TOURNAMENT_BADGE_HEIGHT : 0) -
    NAVBAR_HEIGHT -
    OPPONENT_HEIGHT -
    BOTTOM_PART_HEIGHT;

  const maxBoardWidth = windowWidth - HORIZONTAL_PADDINGS * 2;

  const boardSize = Math.min(maxBoardHeight, maxBoardWidth);
  const boardTopMargin =
    (windowHeight -
      NAVBAR_HEIGHT -
      OPPONENT_HEIGHT -
      BOTTOM_PART_HEIGHT -
      boardSize) /
    2;

  return {
    boardSize: boardSize - (boardSize % 8),
    boardTopMargin,
  };
};

const getMobileBoardSize = ({
  windowWidth,
  windowHeight,

  isTournament,
}: CalculationProps): BoardSizes => {
  const {
    BADGE_HEIGHT,
    BOTTOM_PART_HEIGHT,
    HORIZONTAL_PADDINGS,
    OPPONENT_HEIGHT,
    TOURNAMENT_BADGE_HEIGHT,
  } = MOBILE_SCREEN_SIZES;

  // const maxBoardHeight =
  //   windowHeight -
  //   MOBILE_NAVBAR_HEIGHT -
  //   BADGE_HEIGHT -
  //   OPPONENT_HEIGHT -
  //   BOTTOM_PART_HEIGHT;

  const maxBoardWidth = windowWidth - HORIZONTAL_PADDINGS * 2;

  const boardSize = maxBoardWidth;

  const boardTopMargin =
    (windowHeight -
      MOBILE_NAVBAR_HEIGHT -
      (isTournament ? TOURNAMENT_BADGE_HEIGHT : BADGE_HEIGHT) -
      OPPONENT_HEIGHT -
      BOTTOM_PART_HEIGHT -
      boardSize) /
    2;

  return {
    boardSize: boardSize - (boardSize % 8),
    boardTopMargin: boardTopMargin > 0 ? boardTopMargin : 0,
  };
};

const CalculateBoardSizeByBreakpoint = {
  wideScreen: getWideScreenBoardSize,
  desktop: getDesktopBoardSize,
  tablet: getTabletBoardSize,
  phone: getMobileBoardSize,
};

/**
 * Хук для рассчёта размера доски
 * @param gameType
 * @returns number
 */
export const useChessboardSize = (gameType: GameType) => {
  const [width, height] = useWindowScales();
  const matchingBreakpoint = useMediaBreakpoint({
    wideScreen: (width, { wideScreen }) => width >= wideScreen,
    desktop: (width, { desktop, wideScreen }) =>
      width >= desktop && width < wideScreen,
    tablet: (width, { tablet, desktop }) => width >= tablet && width < desktop,
    phone: (width, { phone }) => width > phone,
  });

  const chessboardSize = useMemo(() => {
    if (typeof document === 'undefined' || !width || !height) return 0;

    const { boardSize, boardTopMargin } = CalculateBoardSizeByBreakpoint[
      matchingBreakpoint
    ]({
      windowWidth: width,
      windowHeight: height,
      isTournament: gameType === GameType.TOURNAMENT,
    });

    document.documentElement.style.setProperty(
      '--boardTopMargin',
      boardTopMargin ? `${boardTopMargin}px` : '0'
    );
    document.documentElement.style.setProperty('--boardSize', `${boardSize}px`);
    return boardSize;
  }, [gameType, matchingBreakpoint, width, height]);

  return chessboardSize;
};

import { useTournamentType } from '@utils/hooks/activeGames/_useTournamentType.hook';
import { FC, memo } from 'react';
import { ITournamentTypeAndRoundProps } from './types';
import { useSwissData } from '@utils/hooks/activeGames/_useSwissData.hook';
import { TournamentTypeAndTimerEndRound } from '@chessarena/components/lib/game/TournamentTypeAndTimerEndRound';
import { useArenaData } from '@utils/hooks/activeGames/_useArenaData.hook';

const ArenaInfo: FC<ITournamentTypeAndRoundProps> = memo(function ArenaInfo({
  gameData,
}) {
  const data = useArenaData(gameData);

  if (!data) {
    return null;
  }

  const { isDangerous, roundEndTimerText, tournamentTypeText } = data;

  return (
    <TournamentTypeAndTimerEndRound
      tournamentType={tournamentTypeText}
      roundEndTimerText={roundEndTimerText}
      isDangerTimer={isDangerous}
    />
  );
});

const SwissInfo: FC<ITournamentTypeAndRoundProps> = memo(function SwissInfo({
  gameData,
}) {
  const data = useSwissData(gameData);

  if (!data) {
    return null;
  }

  const { roundText, tournamentTypeText } = data;

  return (
    <TournamentTypeAndTimerEndRound
      tournamentType={tournamentTypeText}
      roundEndTimerText={roundText}
      isDangerTimer={false}
    />
  );
});

export const TournamentTypeAndRound: FC<ITournamentTypeAndRoundProps> = memo(
  function TournamentTypeAndRound({ gameData }) {
    const tournamentType = useTournamentType(gameData);

    if (!gameData) {
      return null;
    }

    return (
      <>
        {tournamentType === 'swiss' && <SwissInfo gameData={gameData} />}
        {tournamentType === 'arena' && <ArenaInfo gameData={gameData} />}
      </>
    );
  }
);

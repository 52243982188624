"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TournamentTypeAndTimerEndRound = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const style_1 = require("./style");
exports.TournamentTypeAndTimerEndRound = (0, react_1.memo)(function TournamentTypeAndTimerEndRound(props) {
    const { tournamentType, roundEndTimerText, isDangerTimer, size = 's' } = props;
    return ((0, jsx_runtime_1.jsxs)(style_1.$TournamentTypeAndTimerEndRound, { "$size": size, children: [(0, jsx_runtime_1.jsx)(style_1.$TournamentType, { children: tournamentType }), ' · ', (0, jsx_runtime_1.jsx)(style_1.$RoundEndTimerText, { "$isDangerTimer": isDangerTimer, children: roundEndTimerText })] }));
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PlayerInfoSizesMap = void 0;
exports.PlayerInfoSizesMap = {
    s: {
        avatarSize: 28,
        nameSize: 12,
    },
    m: {
        avatarSize: 32,
        nameSize: 14,
    },
    l: {
        avatarSize: 40,
        nameSize: 16,
    },
};

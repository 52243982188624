import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localeData from 'dayjs/plugin/localeData';
import { TournamentType, TournamentStatus, TournamentBoardStatus, } from '../types';
dayjs.extend(duration);
dayjs.extend(localeData);
/**
 * возвращает текстовый tournamentType
 * @param {number} type - id tournamentType
 * @returns {tournamentType} string
 */
export const getTournamentType = (type) => {
    switch (type) {
        case TournamentType.MATCH:
            return 'match';
        case TournamentType.PLAYOFF:
            return 'playoff';
        case TournamentType.SWISS:
            return 'swiss';
        case TournamentType.ARENA:
            return 'arena';
        default:
            return 'swiss';
    }
};
/**
 * возвращает текстовый tournament status
 * @param {TournamentStatus} status - id tournamentType
 * @returns {tournamentStatus} string
 */
export const getTournamentStatus = (status) => {
    switch (status) {
        // online + broadcast
        case TournamentStatus.EXPECTED:
            return 'upcoming';
        case TournamentStatus.GOES:
            return 'in_progress';
        case TournamentStatus.COMPLETED:
            return 'ended';
        default:
            return 'upcoming';
    }
};
/**
 * возвращает количество игроков в турнире
 * @param {number} signedUpAmount - кол-во зарегестрированных игроков
 * @param {number} allowedAmount - макс. кол-во ишроков
 * @param {TournamentStatus} tournamentStatus - статус турнира
 * @param {TournamentType} tournamentType - тип турнира
 * @param {number} registeredPlayers - кол-во зарегестрированных игроков (в том числе исключенных)
 * @returns {playersAmount} string
 */
export const getTournamentPlayersAmount = ({ signedUpAmount, allowedAmount, tournamentStatus, tournamentType = TournamentType.SWISS, registeredPlayers, }) => {
    if (tournamentType === TournamentType.ARENA)
        return `${signedUpAmount}`;
    if (tournamentStatus === TournamentStatus.EXPECTED) {
        return `${signedUpAmount}/${allowedAmount}`;
    }
    return `${registeredPlayers ? registeredPlayers : signedUpAmount}`;
};
/**
 * возвращает длительность арена турнира
 * @param {string} startTime - время начала турнира
 * @param {string} endTime - время конца турнира
 * @returns {duration} string
 */
export const getArenaTournamentDuration = ({ startTime, endTime, l = {
    hours: 'h',
    min: 'min',
}, }) => {
    const startMoment = dayjs(startTime);
    const endMoment = dayjs(endTime);
    const momentDiff = dayjs.duration(endMoment.diff(startMoment));
    const hoursDiff = momentDiff.hours();
    const minutesDiff = momentDiff.minutes();
    const hours = hoursDiff > 0 ? `${hoursDiff} ${l.hours}` : '';
    const minutes = minutesDiff > 0 ? `${minutesDiff} ${l.min}` : '';
    return `${hours} ${minutes}`;
};
/**
 * возвращает целую часть числа
 * @param {number} number - число
 */
export const getIntegerNumber = (number) => {
    return Math.trunc(number);
};
/**
 * проверяет число на наличие 1/2
 * @param {number} number - число
 */
export const haveHalfValue = (number) => {
    return number - Math.trunc(number) > 0;
};
/**
 * проверяет закончена ли доска
 * @param {TournamentBoardStatus} boardStatus - статус доски
 */
export const boardCompleted = (boardStatus) => boardStatus === TournamentBoardStatus.COMPLETED;
/**
 * проверяет ожидаемая ли доска
 * @param {TournamentBoardStatus} boardStatus - статус доски
 */
export const boardExpected = (boardStatus) => boardStatus === TournamentBoardStatus.EXPECTED;
/**
 * проверяет идет ли доска
 * @param {TournamentBoardStatus} boardStatus - статус доски
 */
export const boardGoes = (boardStatus) => boardStatus === TournamentBoardStatus.GOES;

import { useMemo } from 'react';
import { useBoardSettingsData } from '../../store/storeshed';

/**
 * Хук собирающий текущие настройки для доски для передачи их в чессган
 */
export const useChessboardSettingsForBoard = () => {
  const theme = useBoardSettingsData('boardTheme');
  const lmTheme = useBoardSettingsData('lastMoveTheme');

  return useMemo(
    () => ({
      theme,
      lmTheme,
    }),
    [lmTheme, theme]
  );
};

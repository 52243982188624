export const getPieceRotate = (checkmate, rotate) => {
    if (rotate) {
        if (checkmate)
            return 210;
        return 180;
    }
    if (checkmate)
        return 30;
    return 0;
};

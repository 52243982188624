import { Chessgun, chessSide } from 'chessgun/core';
import { useEffect, useMemo, useState } from 'react';
import { syncedDate } from 'shared/helpers/_common';
import {
  GameRatingMode,
  GameResult,
  GameStatus,
  SocketStatus,
  oppMode,
} from 'shared/types';

import { eDoneReason } from '@store/context/_common.types';
import { useCurrentGame } from '@store/context/lobby_context/hooks/_use_current_game.hook';
import { useCurrentGameData } from '@store/context/lobby_context/hooks/_use_current_game_data.hook';
import { useAuthStore, useUserDataStore } from '@store/storeshed';
import { GameType, GameUserType } from '@types';
import { formatNGResult } from '@utils/_helpers';
import { getGameStatus, getPlayersPreMoveMs } from '@utils/_ng_helpers';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { IPlayerWsStatus, TNGPageActions } from '../_types';
import { useCurrentSettings } from './_useCurrentSettings.hook';
dayjs.extend(duration);

const FIVE_SEC = 5000;

export const useNGContextComputed = (actions: TNGPageActions) => {
  const currentGame = useCurrentGame();
  const gameData = useCurrentGameData();
  const currentSettings = useCurrentSettings();

  const uid = useAuthStore('uid');
  const userData = useUserDataStore('data');
  // const anotherBrowserLogin = useWebSocketStore('another_browser_login');

  const [showOfflineStatus, setShowOfflineStatus] = useState(false);
  const [gameWsStatus, setGameWsStatus] = useState<SocketStatus | null>(null);
  const [inviteChallengeRequested, setInviteChallengeRequested] =
    useState(false);
  const publicExtra = currentGame?.state?.cfg?.publicExtra || null;

  const boardData =
    gameData?.boardData === undefined ? null : gameData.boardData;

  const dateTimeOfRound =
    currentGame?.state?.cfg?.scheduledPhCfg?.start || null;

  const lossIfDisconnectedForTime =
    currentGame?.state?.cfg.playingPhCfg.lossIfDisconnectedFor;

  const tournamentId =
    publicExtra?.tournament_id || boardData?.tournament?.id || null;
  const tournamentSlug =
    publicExtra?.tournament_slug || boardData?.tournament?.slug || null;

  const gameType = tournamentId ? GameType.TOURNAMENT : GameType.SINGLE_GAME;

  const currPh = useMemo(() => {
    return currentGame?.state?.currPh;
  }, [currentGame?.state?.currPh]);

  const donePh = useMemo(() => {
    return currentGame?.state?.donePh;
  }, [currentGame?.state?.donePh]);

  const turns = useMemo(
    () => currentGame?.state?.playingPh?.turns,
    [currentGame?.state?.playingPh?.turns]
  );

  const analysis = useMemo(() => {
    if (gameData?.analysis === undefined) {
      return null;
    }

    return gameData.analysis;
  }, [gameData]);

  // TODO: удалить после окончания теста регионов
  const isMultiregion = useMemo(() => {
    if (!currentGame?.state) {
      return false;
    }

    return !!currentGame.state.cfg?.publicExtra.rId;
  }, [currentGame?.state?.cfg?.publicExtra?.rId]);

  const boardDataRequest = useMemo(() => {
    if (gameData?.boardDataRequest === undefined) {
      return false;
    }

    return gameData.boardDataRequest;
  }, [gameData]);

  const myColor = useMemo(() => {
    if (boardData) {
      return boardData.black_player.uid === uid
        ? chessSide.BLACK
        : chessSide.WHITE;
    }

    if (
      !uid ||
      !currentGame?.state?.cfg?.bPId ||
      !currentGame?.state?.cfg?.wPId
    ) {
      return chessSide.WHITE;
    }

    return currentGame?.state?.cfg?.bPId === uid
      ? chessSide.BLACK
      : chessSide.WHITE;
  }, [currentGame, uid, boardData]);

  const { whitePreMoveMsLeft, blackPreMoveMsLeft } = useMemo(() => {
    return getPlayersPreMoveMs({
      currentMoveData: currentGame?.state?.playingPh?.currTurn,
      playingPhCfg: currentGame?.state?.cfg?.playingPhCfg,
    });
  }, [
    currentGame?.state?.cfg?.playingPhCfg,
    currentGame?.state?.playingPh?.currTurn,
  ]);

  const whitePlayer = useMemo(() => {
    if (!boardData?.white_player) return null;

    return boardData?.white_player;
  }, [boardData?.white_player]);

  const blackPlayer = useMemo(() => {
    if (!boardData?.black_player) return null;

    return boardData?.black_player;
  }, [boardData?.black_player]);

  const { result, endReason } = useMemo(() => {
    let result: GameResult | null = null;
    if (donePh?.result) {
      result = formatNGResult(donePh.result);
    }

    if (boardData?.result) {
      result = boardData.result;
    }

    return {
      result,
      endReason: donePh?.reason ? donePh.reason : null,
    };
  }, [donePh, boardData?.result]);

  const gameUserType = useMemo(() => {
    const cfg = currentGame?.state?.cfg;
    if (!uid) return GameUserType.NONE;

    const whitePlayerUid = cfg?.wPId || whitePlayer?.uid;
    const blackPlayerUid = cfg?.bPId || blackPlayer?.uid;
    if (uid === whitePlayerUid || uid === blackPlayerUid) {
      return GameUserType.PLAYER;
    }

    return GameUserType.VIEWER;
  }, [currentGame?.state?.cfg, uid, whitePlayer, blackPlayer]);

  const engine = useMemo(() => {
    if (currentGame?.chessgun) {
      const history = currentGame.chessgun.get().history;
      actions.setMovesHistory(history);
      actions.setMovesHistoryLength(history.length);

      return currentGame.chessgun;
    }

    if (boardData?.result && !currentGame) {
      const chessgun = new Chessgun();

      const moves = boardData.moves;
      const engineHistory = chessgun.get('history');

      if (moves?.length && engineHistory.length === 0) {
        const lastMove = moves[moves.length - 1];

        chessgun.loadFen(lastMove.fen);

        chessgun.uploadHistory({
          notations: moves.map((move) => move.long_san),
        });
      }

      const history = chessgun.get().history;
      actions.setMovesHistory(history);
      actions.setMovesHistoryLength(history.length);

      return chessgun;
    }

    return null;
  }, [currentGame?.chessgun, boardData]);

  const gameStatus = useMemo(() => {
    return getGameStatus(boardData, currPh, donePh);
  }, [boardData, currPh, donePh]);

  const opponentMode = useMemo(() => {
    if (!boardData?.rematch_opp_mode) return oppMode.BOT;

    return boardData.rematch_opp_mode;
  }, [boardData?.rematch_opp_mode]);

  const playerWsStatus: IPlayerWsStatus | null = useMemo(() => {
    const isInitialConnecting =
      currentGame?.ws?.isInitialConnectionSetup ?? true;

    if (isInitialConnecting && gameUserType === GameUserType.PLAYER) {
      return {
        disconnected: false,
        isInitiallyConnectingUntilEstablished: isInitialConnecting,
        isInitialConnectionInStatusConnecting:
          gameWsStatus === SocketStatus.CONNECTING,
      };
    }

    if (
      gameWsStatus &&
      showOfflineStatus &&
      gameUserType === GameUserType.PLAYER
    ) {
      const disconnected = gameWsStatus !== SocketStatus.CONNECTED;

      return {
        disconnected,
        isInitiallyConnectingUntilEstablished: isInitialConnecting,
        isInitialConnectionInStatusConnecting: false,
      };
    }

    return null;
  }, [
    currentGame?.ws?.isInitialConnectionSetup,
    gameUserType,
    gameWsStatus,
    showOfflineStatus,
  ]);

  const opponentSessions = useMemo(() => {
    if (currentGame?.state?.misc && showOfflineStatus) {
      return myColor === chessSide.WHITE
        ? {
            sessions: currentGame.state.misc.bSessions,
            sessionsTs: currentGame.state.misc.bSessionsTs,
          }
        : {
            sessions: currentGame.state.misc.wSessions,
            sessionsTs: currentGame.state.misc.wSessionsTs,
          };
    }

    return {
      sessions: null,
      sessionsTs: null,
    };
  }, [currentGame?.state?.misc, myColor, showOfflineStatus]);

  const opponentDisconnected = useMemo(() => {
    if (currentGame?.state?.misc && showOfflineStatus) {
      return opponentSessions.sessions === 0;
    }

    return false;
  }, [currentGame?.state?.misc, opponentSessions, showOfflineStatus]);

  const opponentDisconnectedTs = useMemo(() => {
    if (opponentDisconnected) {
      return opponentSessions.sessionsTs;
    }

    return null;
  }, [opponentDisconnected, opponentSessions.sessionsTs]);

  const opponentDisconnectedLoseTs = useMemo(() => {
    if (opponentDisconnectedTs && lossIfDisconnectedForTime) {
      return opponentDisconnectedTs + lossIfDisconnectedForTime;
    }
  }, [lossIfDisconnectedForTime, opponentDisconnectedTs]);

  const isAbort = useMemo(() => {
    const abortReasons = [
      eDoneReason.ABORT_TIMEOUT_BOTH_CONNECTIONS,
      eDoneReason.ABORT_TIMEOUT_FIRST_MOVE_WHITE,
      eDoneReason.ABORT_TIMEOUT_FIRST_MOVE_BLACK,
      eDoneReason.ABORT_MANUAL_CONNECTING_WHITE,
      eDoneReason.ABORT_MANUAL_CONNECTING_BLACK,
      eDoneReason.ABORT_MANUAL_FIRST_MOVE_WHITE,
      eDoneReason.ABORT_MANUAL_FIRST_MOVE_BLACK,
    ];

    return (
      gameType === GameType.SINGLE_GAME &&
      !!endReason &&
      abortReasons.includes(endReason)
    );
  }, [endReason, gameType]);

  const gameRatingMode = useMemo(() => {
    if (!boardData) return GameRatingMode.UNRATED;
    return boardData.rating;
  }, [boardData?.rating]);

  const opponent = useMemo(() => {
    if (!boardData) return null;
    return boardData.white_player.uid === uid
      ? boardData.black_player
      : boardData.white_player;
  }, [boardData, uid]);

  // TODO: Рефактор
  // Этот эффект устанавливает флаг того нужно ли отображать какие-то оффлайн статусы
  // Такие как: Плашка дисконнекта у опонента и себя
  // Выглядит странно и непонятны 5 секунд
  // Без этой логики мы будем видить дисконект плашку почти сразу же при подключении к игре ибо есть небольшое время
  // между фазами коннектов сокетов. Пока оставлю, но лучше бы такое как-то зарефакторить
  useEffect(() => {
    if (
      !currentGame?.state?.playingPh?.phStart ||
      gameUserType === GameUserType.VIEWER
    ) {
      setShowOfflineStatus(false);
      return;
    }

    function isMoreFiveSecAfterStart(phEnd: number) {
      const diff = syncedDate().getTime() - phEnd;
      return diff > FIVE_SEC;
    }

    const isMore = isMoreFiveSecAfterStart(currentGame.state.playingPh.phStart);
    if (!isMore) {
      setTimeout(() => {
        setShowOfflineStatus(true);
      }, FIVE_SEC);
    } else {
      setShowOfflineStatus(true);
    }
  }, [currentGame?.state?.playingPh?.phStart, gameUserType]);

  useEffect(() => {
    if (gameStatus === GameStatus.ENDED || gameStatus === GameStatus.ABORTED) {
      setShowOfflineStatus(false);
    }
  }, [gameStatus]);

  useEffect(() => {
    if (currentGame?.ws) {
      setGameWsStatus(currentGame.ws.status);
      currentGame.ws.onStatusChange = (status) => {
        setGameWsStatus(status);
      };

      return () => {
        if (currentGame.ws.userType === 'viewer') currentGame.ws.disconnect();
        currentGame.ws.onStatusChange = () => {};
        setGameWsStatus(null);
      };
    }
  }, [currentGame?.ws]);

  useEffect(() => {
    const suspicionReport = boardData?.suspicion_reports?.find(
      (item) => item.sender_id === userData?.player?.player_id
    );
    actions.setGameReported(!!suspicionReport);
  }, [boardData?.suspicion_reports, userData]);

  useEffect(() => {
    if (boardData) {
      if (gameStatus === GameStatus.ENDED && !inviteChallengeRequested) {
        actions.getIncomingInviteChallenge(boardData);
        actions.getOutcomingInviteChallenge(boardData);
      }
      setInviteChallengeRequested(true);
    }
  }, [gameStatus, boardData, inviteChallengeRequested]);

  return {
    currPh,
    myColor,
    currentGame,
    whitePreMoveMsLeft,
    blackPreMoveMsLeft,
    whitePlayer,
    blackPlayer,
    endReason,
    isAbort,
    result,
    gameUserType,
    engine,
    analysis,
    boardDataRequest,
    boardData,
    gameStatus,
    uid,
    opponentMode,
    gameType,
    playerWsStatus,
    opponentDisconnected,
    gameRatingMode,
    isMultiregion,
    dateTimeOfRound,
    tournamentId,
    tournamentSlug,
    turns,
    currentSettings,
    lossIfDisconnectedForTime,
    opponentDisconnectedLoseTs,
    opponent,
  };
};

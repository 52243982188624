import { useMediaBreakpoint } from '@chessarena/components/lib/utils/useMediaBreakpoint';

/**
 * Хук возвращающий название брейкпоинта проекта
 * TODO: Переимновать название хука на лучшее
 */
export const useProjectBreakpoints = () => {
  return useMediaBreakpoint({
    wideScreen: (width, { wideScreen }) => width >= wideScreen,
    largeDesktop: (width, { largeDesktop, wideScreen }) =>
      width >= largeDesktop && width < wideScreen,
    desktop: (width, { desktop, largeDesktop }) =>
      width >= desktop && width < largeDesktop,
    tablet: (width, { tablet, desktop }) => width >= tablet && width < desktop,
    phone: (width, { tablet }) => width < tablet,
  });
};

import { ActiveGamesPopup } from '@chessarena/components/lib/active-games/ActiveGamesPopup';
import { TournamentBadgeTitle } from '@chessarena/components/lib/general/TournamentBadgeTitle';
import { TournamentBadgeTitleRenderLink } from '@chessarena/components/lib/general/TournamentBadgeTitle/types';
import { NotificationCard } from '@components/atoms/activeGames/NotificationCard/NotificationCard';
import { routesConstants } from '@constants';
import { ActiveGame } from '@store/context/active-games/_types';
import { useIsTournament } from '@utils/hooks/activeGames/_useIsTournament.hook';
import { useActiveGameTournamentData } from '@utils/hooks/activeGames/_useTournamentData.hook';
import Link from 'next/link';
import { FC, memo } from 'react';

interface IActiveGamesListItem {
  activeGame: ActiveGame;
  playerUid: string | null;
  onClick: () => void;
}

const renderTournamentBadgeLink: TournamentBadgeTitleRenderLink = (
  link,
  children
) => {
  return (
    <Link prefetch={false} href={link} type="secondary">
      {children}
    </Link>
  );
};

export const ActiveGamesListItem: FC<IActiveGamesListItem> = memo(
  function ActiveGamesListItem({ activeGame, playerUid, onClick }) {
    const { boardId, engine, gameData } = activeGame;

    const isTournament = useIsTournament(gameData);
    const tournamentData = useActiveGameTournamentData(gameData);

    if (!gameData) {
      return null;
    }

    return (
      <ActiveGamesPopup.ListItem key={boardId}>
        {isTournament && (
          <TournamentBadgeTitle
            tournamentLink={`${routesConstants.TOURNAMENTS}/${tournamentData?.tournament_slug}`}
            renderLink={renderTournamentBadgeLink}
            tournamentTitle={tournamentData?.tournament_title ?? ''}
          />
        )}

        <NotificationCard
          boardId={boardId}
          gameData={gameData}
          engine={engine}
          playerUid={playerUid}
          onClick={onClick}
        />
      </ActiveGamesPopup.ListItem>
    );
  }
);

import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useLobbyContext } from '../_lobby.context';

export const useCurrentGame = () => {
  const router = useRouter();
  const id = router.query.id as string;

  const {
    state: { games },
  } = useLobbyContext();

  const currentGame = useMemo(() => {
    const game = games[id];

    return game ? game : null;
  }, [games, id]);

  return currentGame;
};

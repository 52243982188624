import React, { useCallback, useMemo } from 'react';
import Link from 'next/link';

import { useApplicationContext } from '@application';

import { eFideIdStatus } from '@types';
import { eAccessLevel } from 'shared/types';
import { headerAccountPopupItems } from './_constants';

import { useAuthStore } from '@store/storeshed';
import { useUserDataStore } from '@store/storeshed';

import { useFideIdStatus } from '@utils/hooks/_useFideIdStatus.hook';
import {
  SpecialButtonClickEventParams,
  SpecialButtonIconTypes,
  SpecialButtonTypes,
} from '@chessarena/components/lib/layout/SpecialButton/types';
import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { openPopup } from '@utils/_router';
import { ePopupPaths, routesConstants } from '@constants';

export const useCustomHeaderSpecialButton = () => {
  const { localization: l } = useApplicationContext();

  const fideIdStatus = useFideIdStatus();

  const userData = useUserDataStore('data');
  const logged = useAuthStore('logged');
  const accessLevel = userData?.access_level;

  const action = useMemo(() => {
    if (!logged)
      return {
        ...headerAccountPopupItems.register,
        label: l.header.account.popup.items.register.special_title,
        type: 'green' as SpecialButtonTypes,
        icon: 'king' as SpecialButtonIconTypes,
        readMore: {
          text: l.header.account.popup.items.register.read_more,
          href: routesConstants.PRICING,
        },
      };

    if (accessLevel === eAccessLevel.PRO) {
      if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
        return {
          ...headerAccountPopupItems.fide_id,
          label: l.header.account.popup.items.fide_id.title.connect_fide_id,
          type: 'capri' as SpecialButtonTypes,
          icon: 'foa' as SpecialButtonIconTypes,
          readMore: {
            text: l.header.account.popup.items.fide_id.title.read_more,
            href: routesConstants.PRICING,
          },
        };
      return null;
    }

    return {
      ...headerAccountPopupItems.subscription,
      label: l.header.account.popup.items.subscription.title.upgrade,
      type: 'yellow' as SpecialButtonTypes,
      icon: 'king' as SpecialButtonIconTypes,
      link: routesConstants.PRICING,
      readMore: {
        text: l.header.account.popup.items.subscription.title.read_more,
        href: routesConstants.PRICING,
      },
    };
  }, [l, logged, accessLevel, fideIdStatus]);

  const renderLink = (params: RenderLinkParams) => {
    const { link, children } = params;

    return (
      <Link href={link} prefetch={false}>
        {children as React.ReactNode}
      </Link>
    );
  };

  const onFideIdClick = useCallback(() => {
    if (fideIdStatus === eFideIdStatus.NOT_CONNECTED)
      openPopup(`?popup=${ePopupPaths.FIDE}`);
  }, [fideIdStatus]);

  const onButtonClick = ({ id }: SpecialButtonClickEventParams) => {
    switch (id) {
      case 'register':
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
        break;
      case 'fideId':
        onFideIdClick();
        break;
      default:
        break;
    }
  };

  if (!action) return undefined;

  return {
    ...action,
    onClick: onButtonClick,
    renderLink,
  };
};

import { GameType, GameUserType } from '@types';
import { useMemo } from 'react';
import { GameStatus } from 'shared/types';
/**
 * Хук для понимания, может ли у игры быть панель рематча/новой игры или нет
 * Базируется на:
 * 1) Закончилась ли игра
 * 2) Начинается ли новая (случай с рематчем)
 * 3) Юзер, который находится на странице = игрок
 * 4) Турнирная ли игра
 *
 * @param gameStatus
 * @param gameUserType
 * @param tourGameData
 * @returns boolean
 */
export const useIsGameAvailableForRematch = (
  gameStatus: GameStatus,
  player: 'bottom' | 'top',
  gameUserType?: GameUserType,
  gameType?: GameType
) => {
  const isGameAvailableForRematch = useMemo(() => {
    const isGameEnded = gameStatus === GameStatus.ENDED;

    return (
      player === 'top' &&
      isGameEnded &&
      gameUserType === GameUserType.PLAYER &&
      gameType === GameType.SINGLE_GAME
    );
  }, [gameStatus, gameUserType, gameType, player]);

  return isGameAvailableForRematch;
};

import { useApplicationContext } from '@application';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import {
  getTimeToEnd,
  isLessThanTenMinutesUntilTournamentEnd,
} from '@utils/_tournamentEnd';
import { capitalizeString } from 'shared/helpers/_common';
import { getMsFromNow } from 'shared/helpers/_game';
import { getTournamentType } from 'shared/helpers/_tournament';
import { useCountdown } from '@utils/hooks/_useCountdown';
import { TournamentStatus } from 'shared/types';
import { useActiveGameTournamentData } from './_useTournamentData.hook';

export const useArenaData = (gameData: ILobbyContextGame) => {
  const { localization: l } = useApplicationContext();
  const tournamentData = useActiveGameTournamentData(gameData);

  const { milliseconds } = useCountdown({
    timeInMilliseconds: getMsFromNow(tournamentData?.tournament_finish ?? 0),
  });

  if (
    !gameData ||
    !tournamentData?.tournament_finish ||
    !tournamentData?.tournament_kind
  )
    return null;

  const tournamentTypeText = capitalizeString(
    getTournamentType(tournamentData?.tournament_kind)
  );

  const tournamentEndTime = tournamentData.tournament_finish;

  const lessThanTenMinutes =
    isLessThanTenMinutesUntilTournamentEnd(tournamentEndTime);

  const endedText = l?.tournament_game?.ended;
  const endsInText = l?.game_page?.ends_in;
  const formattedTime = getTimeToEnd(milliseconds);

  const text =
    tournamentData.tournament_status === TournamentStatus.GOES
      ? `${endsInText} ${formattedTime}`
      : endedText;

  return {
    roundEndTimerText: text,
    isDangerous: lessThanTenMinutes,
    tournamentTypeText,
  };
};

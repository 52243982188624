import { useMemo } from 'react';

import { useApplicationContext } from '@application';
import { ButtonType } from '@chessarena/components/lib/general/Button/types';

import { ePopupPaths } from '@constants';
import { openPopup } from '@utils/_router';
import { useAuthStore } from '@store/storeshed';

export const useCustomHeaderAuth = () => {
  const { localization: l } = useApplicationContext();

  const logged = useAuthStore('logged');

  const items = useMemo(() => {
    if (logged) return undefined;
    const signIn = {
      id: 'signIn',
      type: 'primary' as ButtonType,
      label: l?.header.account.auth.actions.log_in,
    };

    const register = {
      id: 'register',
      type: 'secondary' as ButtonType,
      label: l?.header.account.auth.actions.create_account,
    };

    return [signIn, register];
  }, [l, logged]);

  const onButtonClick = (id: string) => {
    switch (id) {
      case 'signIn':
        window.scrollTo({ top: 0, behavior: 'smooth' });
        openPopup(`?popup=${ePopupPaths.SIGN_IN}`);
        break;
      case 'register':
        openPopup(`?popup=${ePopupPaths.REGISTRATION}`);
        break;
      default:
        break;
    }
  };

  if (!items) return undefined;

  return {
    items,
    onItemClick: onButtonClick,
  };
};

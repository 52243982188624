import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';

export const useActiveGameTournamentData = (
  gameData: ILobbyContextGame | null
) => {
  if (!gameData) return null;

  return {
    tour_id: gameData?.state?.cfg?.publicExtra.tour_id,
    tour_number: gameData?.state?.cfg?.publicExtra.tour_number,
    tournament_finish: gameData?.state?.cfg?.publicExtra.tournament_finish,
    tournament_id: gameData?.state?.cfg?.publicExtra.tournament_id,
    tournament_slug: gameData?.state?.cfg?.publicExtra.tournament_slug,
    tournament_status: gameData?.state?.cfg?.publicExtra.tournament_status,
    tournament_title: gameData?.state?.cfg?.publicExtra.tournament_title,
    tournament_kind: gameData?.state?.cfg?.publicExtra.tournament_kind,
  };
};

export const getCastlingMovePiece = (moveData, piece) => {
    const { rook, king } = moveData;
    if (piece.position === rook.from) {
        return Object.assign(Object.assign({}, piece), { position: rook.to });
    }
    if (piece.position === king.from) {
        return Object.assign(Object.assign({}, piece), { position: king.to });
    }
    return piece;
};

import React, { useEffect, useState } from "react";
import { DEFAULT_POSITION } from "../../core/_constants";
import { cgDataTypes } from "../../ui";
import { getFileAndRankIndexesFromPosition } from "../../ui/_helpers";
import { under_attack_square_color } from "../../ui/_styles";
import { usePrevious } from "../hooks/usePrevious";
import { createPieces } from "./helpers/createPieces";
import { getUpdatedPieces } from "./helpers/getUpdatedPieces";
import { getPieceRotate } from "./helpers/getPieceRotate";
const isMoveAdded = (oldNotation, newNotation) => {
    var _a, _b;
    const oldNotationMoveNumber = ((_a = oldNotation[oldNotation.length - 1]) === null || _a === void 0 ? void 0 : _a.moveNumber) || 0;
    const newNotationMoveNumber = ((_b = newNotation[newNotation.length - 1]) === null || _b === void 0 ? void 0 : _b.moveNumber) || 0;
    return oldNotationMoveNumber + 1 === newNotationMoveNumber;
};
const isMovesChanged = (oldNotation, newNotation) => {
    var _a, _b, _c, _d;
    if (!oldNotation)
        return true;
    const oldNotationMoveNumber = ((_a = oldNotation[oldNotation.length - 1]) === null || _a === void 0 ? void 0 : _a.moveNumber) || 0;
    const newNotationMoveNumber = ((_b = newNotation[newNotation.length - 1]) === null || _b === void 0 ? void 0 : _b.moveNumber) || 0;
    if (oldNotationMoveNumber > newNotationMoveNumber)
        return true;
    if (oldNotationMoveNumber + 1 < newNotationMoveNumber)
        return true;
    for (let i = 0; i < oldNotation.length; i++) {
        if (((_c = oldNotation[i]) === null || _c === void 0 ? void 0 : _c.fen) !== ((_d = newNotation[i]) === null || _d === void 0 ? void 0 : _d.fen))
            return true;
    }
    return false;
};
export const Pieces = ({ history, rotate }) => {
    const [pieces, setPieces] = useState([]);
    const [defaultPieces] = useState(createPieces(DEFAULT_POSITION));
    const previousHistory = usePrevious(history);
    useEffect(() => {
        if (!history.length)
            return setPieces(createPieces(DEFAULT_POSITION));
        const $isMoveAdded = !!previousHistory && isMoveAdded(previousHistory, history);
        const $isMovesChanged = isMovesChanged(previousHistory, history);
        if (!$isMoveAdded && !$isMovesChanged)
            return;
        const lastHistoryItem = history[history.length - 1];
        const prevHistoryItem = history[history.length - 2];
        const { fen, lan } = lastHistoryItem;
        const $pieces = (() => {
            const isFirstCreation = !pieces.length;
            if (isFirstCreation || $isMovesChanged) {
                return createPieces(fen);
            }
            else if ($isMoveAdded) {
                const prevFen = !(previousHistory === null || previousHistory === void 0 ? void 0 : previousHistory.length) ? DEFAULT_POSITION : prevHistoryItem.fen;
                return getUpdatedPieces({
                    fen: prevFen,
                    lan,
                    pieces,
                });
            }
            return [];
        })();
        setPieces($pieces);
    }, [history]);
    return (React.createElement(React.Fragment, null, (pieces.length ? pieces : defaultPieces).map(({ bgTag, position, id, check, checkmate, color, type }) => {
        const { file, rank } = getFileAndRankIndexesFromPosition(position);
        return (position !== -1 && (React.createElement("div", { key: id, style: {
                position: "absolute",
                top: "0",
                left: "0",
                transition: "100ms",
                width: "calc(100% / 8)",
                height: "calc(100% / 8)",
                transform: `translate(${file * 100}%, ${rank * 100}%)`,
            } },
            React.createElement("span", { className: "check-highlight", "data-cg-type": cgDataTypes.squares.CHECK, style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    transition: "100ms",
                    backgroundColor: check || checkmate ? under_attack_square_color : "transparent",
                } }),
            React.createElement("div", { "data-cg-type": cgDataTypes.PIECE, className: color + type, style: {
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundPosition: "50% 50%",
                    backgroundSize: "100%",
                    transform: `rotate(${getPieceRotate(checkmate, rotate)}deg)`,
                } }))));
    })));
};

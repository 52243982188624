"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveGamesPopup = void 0;
const ActiveGamesPopup_1 = require("./ActiveGamesPopup");
const ActiveGamesPopupHeader_1 = require("./ActiveGamesPopupHeader");
const ActiveGamesPopupHeaderLink_1 = require("./ActiveGamesPopupHeaderLink");
const ActiveGamesPopupHeaderText_1 = require("./ActiveGamesPopupHeaderText");
const ActiveGamesPopupList_1 = require("./ActiveGamesPopupList");
const ActiveGamesPopupListItem_1 = require("./ActiveGamesPopupListItem");
const ActiveGamesPopupComposition = ActiveGamesPopup_1.ActiveGamesPopup;
exports.ActiveGamesPopup = ActiveGamesPopupComposition;
ActiveGamesPopupComposition.Header = ActiveGamesPopupHeader_1.ActiveGamesPopupHeader;
ActiveGamesPopupComposition.HeaderText = ActiveGamesPopupHeaderText_1.ActiveGamesPopupHeaderText;
ActiveGamesPopupComposition.HeaderLink = ActiveGamesPopupHeaderLink_1.ActiveGamesPopupHeaderLink;
ActiveGamesPopupComposition.List = ActiveGamesPopupList_1.ActiveGamesPopupList;
ActiveGamesPopupComposition.ListItem = ActiveGamesPopupListItem_1.ActiveGamesPopupListItem;

import React, { memo } from 'react';

import Header from '@chessarena/components/lib/layout/Header';
import { AlertPopup } from '@components/molecules/popups/_AlertPopup';

import { CustomHeaderContextProvider } from './_context';

import { useCustomHeaderData } from './_useCustomHeaderData.hook';

const CustomHeaderWithContext: React.FC<{ gameInProccess?: boolean }> = memo(
  function CustomHeader(props) {
    const { gameInProccess } = props;
    const {
      home,
      menu,
      subMenu,
      themes,
      auth,
      notifications,
      account,
      challenges,
      challengesAlert,
      activeGames,
      specialButton,
      captions,
    } = useCustomHeaderData();

    return (
      <>
        <Header
          home={home}
          menu={menu}
          subMenu={subMenu}
          auth={auth}
          account={account}
          notifications={notifications}
          themes={themes}
          challenges={{
            ...challenges,
            hidden: gameInProccess ? true : challenges.hidden,
          }}
          activeGames={activeGames}
          specialButton={specialButton}
          captions={captions}
        />
        {challengesAlert.isAlertPopupShown && (
          <AlertPopup {...challengesAlert} />
        )}
      </>
    );
  }
);

export const CustomHeader: React.FC<{
  gameInProccess?: boolean;
}> = memo(function CustomHeader(props) {
  const { gameInProccess } = props;
  return (
    <CustomHeaderContextProvider>
      <CustomHeaderWithContext gameInProccess={gameInProccess} />
    </CustomHeaderContextProvider>
  );
});

import { IGamePlayer } from 'shared/types';

export const getPlayerTitle = (
  player: Pick<IGamePlayer, 'otb_title' | 'foa_title_wchess'>
) => {
  if (player.otb_title && player.otb_title.length > 0) {
    return player.otb_title;
  }

  if (
    !player.otb_title &&
    player.foa_title_wchess &&
    player.foa_title_wchess.length > 0
  ) {
    return player.foa_title_wchess;
  }

  return undefined;
};

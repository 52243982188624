import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';

export const useStartNewGameButton = () => {
  const { localization: l } = useApplicationContext();
  const router = useRouter();

  const onClick = useCallback(() => {
    router.push(routesConstants.HOME);
  }, [router]);

  return useMemo(
    () => ({
      label: l?.active_games?.new_game ?? 'New game',
      loading: false,
      disabled: false,
      onClick,
    }),
    [l?.active_games?.new_game, onClick]
  );
};

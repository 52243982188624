import React from 'react';

import { useProjectBreakpoints } from '@utils/hooks/_useProjectBreakpoints.hook';
import { useChessboardSettingsForBoard } from '@utils/hooks/_useChessboardSettingsForBoard.hook';

import { CGStreamBoard } from 'chessgun/react';

const BoardSizeMap = {
  wideScreen: 160,
  largeDesktop: 160,
  desktop: 160,
  tablet: 140,
  phone: 120,
};

const useBoardSize = () => {
  const breakpoint = useProjectBreakpoints();

  return BoardSizeMap[breakpoint];
};

export const ChallengesBoardFragment = () => {
  const boardSettings = useChessboardSettingsForBoard();
  const boardSize = useBoardSize();

  return (
    <>
      <CGStreamBoard
        {...boardSettings}
        chessboardSize={boardSize}
        history={[]}
      />
    </>
  );
};

import { getLSItem, setLSItem } from '@utils/_local_storage';

export const CAPTURED_FIGURES_VIEW_KEY = 'captured-figures-view';

export const getLSCapturedFiguresView = () => {
  const view = Number(getLSItem(CAPTURED_FIGURES_VIEW_KEY)) ?? 0;

  return view;
};

export const setLSCapturedFiguresView = (value: number) => {
  setLSItem(CAPTURED_FIGURES_VIEW_KEY, value);
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import { IGameReactionsData, IGameReactionsItemAnimation } from '../../_types';
import { ILobbyContextGame } from '@store/context/lobby_context/_lobby_context.types';
import {
  eGameServerNonChessPayloadType,
  eGameServerPayloadType,
} from '@store/context/lobby_context/ws/_types';
import { chessSide } from 'chessgun/core';
import { GameUserType } from '@types';
import { GameStatus } from 'shared/types';
import { GTM_EVENTS, gtmPush } from '@utils/_gtm';

const DISABLE_TIMER = 3000;

function checkSenderClr(myColor: chessSide, senderCrl?: boolean) {
  if (myColor === chessSide.BLACK && senderCrl === true) return true;
  if (myColor === chessSide.WHITE && senderCrl === false) return true;
  return false;
}

export const useReactions = (
  data: IGameReactionsData,
  currentGame: ILobbyContextGame | null,
  myColor: chessSide,
  gameUserType: GameUserType,
  gameStatus: GameStatus,
  movesHistoryLength: number
) => {
  const [visible, setVisible] = useState<boolean | undefined>(undefined);
  const [disabled, setDisabled] = useState(false);
  const [items] = useState(data.items);
  const [opened, setOpened] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState<string | undefined>(undefined);
  const [income, setIncome] = useState<string | undefined>(undefined);
  const [animations, setAnimations] = useState<IGameReactionsItemAnimation[]>(
    []
  );

  const activeAnimation = useMemo(() => {
    if (!active) return undefined;
    const animation = animations.find((item) => item.id === active);
    return animation?.data;
  }, [active, animations]);

  const incomeAnimation = useMemo(() => {
    if (!income) return undefined;
    const animation = animations.find((item) => item.id === income);
    return animation?.data;
  }, [income, animations]);

  const toggleOpened = useCallback(() => {
    if (!disabled) setOpened(!opened);
  }, [disabled, opened, setOpened]);

  const getAnimationData = useCallback(
    async (id: string, income?: boolean) => {
      if (!income) setLoading(true);
      try {
        const item = items.find((item) => item.id === id);
        if (item) {
          const response = await fetch(item.animation);
          const json = await response.json();

          setAnimations((data) => [...data, { id, data: json }]);

          return json;
        }
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [items]
  );

  const sendReaction = useCallback(
    async (id: string) => {
      setDisabled(true);
      currentGame?.ws.reaction({ id });
      const item = items.find((item) => item.id === id);
      if (item) {
        const animation = animations.find((a) => a.id === id);
        if (!animation) await getAnimationData(id);
        setActive(item.id);
        gtmPush({
          event: GTM_EVENTS.SEND_GAME_REACTION,
          paramReactionId: item.id,
        });
      }
    },
    [items, animations, getAnimationData, currentGame]
  );

  const reсeiveIncomeAnimation = useCallback(
    async (id: string) => {
      const item = items.find((item) => item.id === id);
      if (item) {
        const animation = animations.find((a) => a.id === id);
        if (!animation) await getAnimationData(id);
        setIncome(id);
        gtmPush({
          event: GTM_EVENTS.RECEIVED_GAME_REACTION,
          paramReactionId: id,
        });
      }
    },
    [animations, getAnimationData, items]
  );

  const completeAnimation = useCallback(() => {
    setOpened(false);
    setActive(undefined);
    setTimeout(() => {
      setDisabled(false);
    }, DISABLE_TIMER);
  }, []);

  const completeIncomeAnimation = useCallback(() => {
    setIncome(undefined);
  }, []);

  useEffect(() => {
    if (currentGame?.ws) {
      currentGame.ws.pageOnMessage = (e) => {
        if (
          e.payloadType === eGameServerPayloadType.NON_CHESS &&
          e.payload.payloadType === eGameServerNonChessPayloadType.REACTION &&
          checkSenderClr(myColor, e.payload.sender?.clr)
        ) {
          reсeiveIncomeAnimation(e.payload.payload.id);
        }
      };
    }

    return () => {
      if (currentGame?.ws) {
        currentGame.ws.pageOnMessage = () => {};
      }
    };
  }, [currentGame?.ws, myColor, reсeiveIncomeAnimation]);

  useEffect(() => {
    if (gameStatus === GameStatus.NONE) return;
    if (
      gameUserType === GameUserType.PLAYER &&
      visible === undefined &&
      gameStatus === GameStatus.STARTED &&
      movesHistoryLength > 1
    ) {
      setVisible(true);
    }
  }, [gameUserType, gameStatus, movesHistoryLength, visible]);

  return {
    visible,
    items,
    animations,
    opened,
    toggleOpened,
    disabled,
    loading,
    sendReaction,
    activeAnimation,
    completeAnimation,
    incomeAnimation,
    completeIncomeAnimation,
  };
};

"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const style_1 = require("./style");
const Block = (props) => {
    const { size, font, fontWeight, fontStyle, margin, padding, vPadding, hPadding, textAlign, children } = props, rest = __rest(props, ["size", "font", "fontWeight", "fontStyle", "margin", "padding", "vPadding", "hPadding", "textAlign", "children"]);
    return ((0, jsx_runtime_1.jsx)(style_1.$Block, Object.assign({ "data-component": "Block" }, rest, { "$size": size, "$font": font, "$fontWeight": fontWeight, "$fontStyle": fontStyle, "$margin": margin, "$padding": padding, "$vPadding": vPadding, "$hPadding": hPadding, "$textAlign": textAlign, children: children })));
};
exports.default = Block;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useDisclosure = (isOpenDefault = false) => {
    const [opened, setOpened] = (0, react_1.useState)(isOpenDefault);
    const onOpen = (0, react_1.useCallback)(() => setOpened(true), []);
    const onClose = (0, react_1.useCallback)(() => setOpened(false), []);
    const onToggle = (0, react_1.useCallback)(() => setOpened((state) => !state), []);
    return { opened, onOpen, onClose, onToggle };
};
exports.default = useDisclosure;

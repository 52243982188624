export const getNormalMovePiece = (moveData, piece) => {
    const { from, to, capturedFigure } = moveData;
    if (piece.position === capturedFigure) {
        return Object.assign(Object.assign({}, piece), { position: -1 });
    }
    if (piece.position === from) {
        return Object.assign(Object.assign({}, piece), { position: to });
    }
    return piece;
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const context_1 = require("./context");
const style_1 = require("./style");
const PopupOpener = (props) => {
    const { display = 'inline', children } = props;
    const openerNode = (0, react_1.useRef)(null);
    const { setOpenerNode, onOpen, onToggle, closingOnOpenerClick } = (0, react_1.useContext)(context_1.PopupContext);
    const handleClick = (0, react_1.useCallback)(() => {
        if (closingOnOpenerClick && onToggle)
            onToggle();
        if (!closingOnOpenerClick && onOpen)
            onOpen();
    }, [closingOnOpenerClick, onToggle, onOpen]);
    (0, react_1.useEffect)(() => {
        setOpenerNode(openerNode);
    }, [setOpenerNode, openerNode]);
    return ((0, jsx_runtime_1.jsx)(style_1.$PopupOpener, { "data-component": "PopupOpener", ref: openerNode, "$display": display, onClick: handleClick, children: children }));
};
exports.default = PopupOpener;

import { ActiveGamesPopup } from '@chessarena/components/lib/active-games/ActiveGamesPopup';
import { NewGame } from '@components/atoms/activeGames/NewGame/NewGame';
import { useActiveGamesContext } from '@store/context/active-games/_context';
import { FC, memo } from 'react';
import { ActiveGamesListItem } from './ActiveGamesListItem';

interface IActiveGamesListProps {
  closePopup: () => void;
}

export const ActiveGamesList: FC<IActiveGamesListProps> = memo(
  function ActiveGamesList({ closePopup }) {
    const {
      computed: { activeGames, playerUid },
    } = useActiveGamesContext();

    return (
      <ActiveGamesPopup.List>
        {activeGames.map((activeGame) => (
          <ActiveGamesListItem
            key={activeGame.boardId}
            activeGame={activeGame}
            playerUid={playerUid}
            onClick={closePopup}
          />
        ))}

        {activeGames.length === 0 && (
          <ActiveGamesPopup.ListItem>
            <NewGame type="notifications" />
          </ActiveGamesPopup.ListItem>
        )}
      </ActiveGamesPopup.List>
    );
  }
);

export const getPawnMovePiece = (moveData, piece) => {
    const { capturedFigure, from, to, promotion } = moveData;
    if (piece.position === capturedFigure) {
        return Object.assign(Object.assign({}, piece), { position: -1 });
    }
    if (piece.position === from) {
        return Object.assign(Object.assign({}, piece), { position: to, type: promotion ? promotion : piece.type, bgTag: promotion ? piece.bgTag.replace(piece.type, promotion) : piece.bgTag });
    }
    return piece;
};

import { BoardType } from 'shared/types';

export const convertBoardTypeToBoardTypeName = (type: string): BoardType => {
  switch (type) {
    case 'bullet':
      return BoardType.BULLET;
    case 'blitz':
      return BoardType.BLITZ;
    case 'rapid':
      return BoardType.RAPID;
    case 'classic':
      return BoardType.CLASSIC;
    case 'daily':
      return BoardType.DAILY;
    default:
      return BoardType.CLASSIC;
  }
};

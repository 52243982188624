import {
  eNGGamePageContextActionTypes,
  INGPageAction,
  INGPageContextState,
  TNGPageContextReducer,
} from './_types';

export const initState: Omit<INGPageContextState, 'id'> = {
  boardFlipped: false,
  endedAnimationShown: false,
  drawOffered: false,
  drawOfferSended: false,
  opponentOfferedDraw: false,
  threefoldRepetition: false,
  offeredDrawsCount: 0,
  lastDrawOfferedMoveIndex: 0,
  isReviewMode: false,
  gamePgn: '',
  pgnFileName: '',
  gamePgnRequest: false,
  nextGameStatus: null,
  movesHistory: [],
  movesHistoryLength: 0,
  lastMove: null,
  selectedMove: null,
  whiteCaptured: [],
  whiteAdvantage: 0,
  blackCaptured: [],
  blackAdvantage: 0,
  topWarnOpacity: 0,
  bottomWarnOpacity: 0,
  animationTime: 100,
  gameEnded: false,
  caloriesLoss: 0,
  ratingChange: 0,
  newRating: 0,
  rematchOffer: null,
  rematchOfferedRequest: false,
  rematchOfferedCancelRequest: false,
  inviteChallenge: null,
  inviteChallengeRequest: false,
  inviteChallengeAcceptRequest: false,
  inviteChallengeDeclineRequest: false,
  newGameOffer: null,
  newGameOfferedCancelRequest: false,
  newGameOfferedRequest: false,
  topPlayerWarn: false,
  bottomPlayerWarn: false,
  soundPlayed: false,
  tourGameResult: null,
  gameResultRequest: false,
  readyForArena: false,
  isAnalysisPopupOpened: false,
  isGameDataLoaded: false,
  reportGamePopupOpened: false,
  figuresView: 0,
};

export const NGPageContextReducer: TNGPageContextReducer = (
  state: INGPageContextState,
  { type, payload }: INGPageAction
) => {
  switch (type) {
    case eNGGamePageContextActionTypes.SET_CURRENT_GAME: {
      return {
        ...state,
        currentGame: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_BOARD_DATA: {
      return {
        ...state,
        boardData: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BOARD_DATA_REQUEST: {
      return {
        ...state,
        boardDataRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_GAME_PGN: {
      return {
        ...state,
        gamePgn: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_GAME_PGN_NAME: {
      return {
        ...state,
        pgnFileName: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_GAME_PGN_REQUEST: {
      return {
        ...state,
        gamePgnRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_GAME_ZEN_MODE: {
      return {
        ...state,
        isZenMode: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_GAME_REVIEW_MODE: {
      return {
        ...state,
        isReviewMode: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BOARD_FLIPPED: {
      return {
        ...state,
        boardFlipped: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_ENDED_ANIMATION_SHOWN: {
      return {
        ...state,
        endedAnimationShown: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_DRAW_OFFERED: {
      return {
        ...state,
        drawOffered: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_DRAW_OFFER_SENDED: {
      return {
        ...state,
        drawOfferSended: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_OPPONENT_OFFERED_DRAW: {
      return {
        ...state,
        opponentOfferedDraw: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_THREEFOLD_REPETITION: {
      return {
        ...state,
        threefoldRepetition: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_OFFERED_DRAWS_COUNT: {
      return {
        ...state,
        offeredDrawsCount: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_LAST_DRAW_OFFERED_MOVE_INDEX: {
      return {
        ...state,
        lastDrawOfferedMoveIndex: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_NEXT_GAME_STATUS: {
      return {
        ...state,
        nextGameStatus: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_MOVES_HISTORY: {
      return {
        ...state,
        movesHistory: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_MOVES_HISTORY_LENGTH: {
      return {
        ...state,
        movesHistoryLength: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_LAST_MOVE: {
      return {
        ...state,
        lastMove: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_SELECTED_MOVE: {
      return {
        ...state,
        selectedMove: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_WHITE_CAPTURED: {
      return {
        ...state,
        whiteCaptured: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_WHITE_ADVANTAGE: {
      return {
        ...state,
        whiteAdvantage: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BLACK_CAPTURED: {
      return {
        ...state,
        blackCaptured: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BLACK_ADVANTAGE: {
      return {
        ...state,
        blackAdvantage: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_TOP_WARN_OPACITY: {
      return {
        ...state,
        topWarnOpacity: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BOTTOM_WARN_OPACITY: {
      return {
        ...state,
        bottomWarnOpacity: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_ANIMATION_TIME: {
      return {
        ...state,
        animationTime: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_GAME_ENDED: {
      return {
        ...state,
        gameEnded: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_GAME_DATA_LOADED: {
      return {
        ...state,
        isGameDataLoaded: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_CALORIES_LOSS: {
      return {
        ...state,
        caloriesLoss: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_RATING_CHANGE: {
      return {
        ...state,
        ratingChange: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_NEW_RATING: {
      return {
        ...state,
        newRating: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_REMATCH_OFFER: {
      return {
        ...state,
        rematchOffer: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_REQUEST: {
      return {
        ...state,
        rematchOfferedRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_REMATCH_OFFERED_CANCEL_REQUEST: {
      return {
        ...state,
        rematchOfferedCancelRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_ACCEPT_REQUEST: {
      return {
        ...state,
        inviteChallengeAcceptRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_DECLINE_REQUEST: {
      return {
        ...state,
        inviteChallengeDeclineRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_NEW_GAME_OFFER: {
      return {
        ...state,
        newGameOffer: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_REQUEST: {
      return {
        ...state,
        newGameOfferedRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_NEW_GAME_OFFERED_CANCEL_REQUEST: {
      return {
        ...state,
        newGameOfferedCancelRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE: {
      return {
        ...state,
        inviteChallenge: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_INVITE_CHALLENGE_REQUEST: {
      return {
        ...state,
        inviteChallengeRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_TOP_PLAYER_WARN: {
      return {
        ...state,
        topPlayerWarn: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_BOTTOM_PLAYER_WARN: {
      return {
        ...state,
        bottomPlayerWarn: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_SOUND_PLAYED: {
      return {
        ...state,
        soundPlayed: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_TOUR_GAME_RESULT: {
      return {
        ...state,
        tourGameResult: payload,
      };
    }
    case eNGGamePageContextActionTypes.SET_GAME_RESULT_REQUEST: {
      return {
        ...state,
        gameResultRequest: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_READY_FOR_ARENA: {
      return {
        ...state,
        readyForArena: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_IS_ANALYSIS_POPUP_OPENED: {
      return {
        ...state,
        isAnalysisPopupOpened: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_TIME_CONTROL: {
      return {
        ...state,
        timeControl: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_ID: {
      return {
        ...state,
        id: payload,
      };
    }

    case eNGGamePageContextActionTypes.RESET_GAME: {
      return {
        ...state,
        ...initState,
      };
    }

    case eNGGamePageContextActionTypes.SET_REPORT_GAME_POPUP_OPENED: {
      return {
        ...state,
        reportGamePopupOpened: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_GAME_REPORTED: {
      return {
        ...state,
        gameReported: payload,
      };
    }

    case eNGGamePageContextActionTypes.SET_FIGURES_VIEW: {
      return {
        ...state,
        figuresView: payload,
      };
    }

    default:
      return state;
  }
};

import { NotificationCard as Card } from '@chessarena/components/lib/active-games/NotificationCard';
import TimeControlMobile from '@chessarena/components/lib/game/TimeControlMobile';
import { useIsTournament } from '@utils/hooks/activeGames/_useIsTournament.hook';
import { useTimeControlData } from '@utils/hooks/activeGames/_useTimeControlData.hook';
import { FC, memo } from 'react';
import { TournamentTypeAndRound } from '../TournamentTypeAndRound/TournamentTypeAndRound';
import { INotificationCardHeaderProps } from './types';

export const NotificationCardHeader: FC<INotificationCardHeaderProps> = memo(
  function NotificationCardHeader({ gameData }) {
    const timeControlData = useTimeControlData(gameData);
    const isTournament = useIsTournament(gameData);

    if (!timeControlData) {
      return null;
    }

    const { rating, ratingType, time, timeControlTypeName } = timeControlData;

    return (
      <>
        <Card.HeaderContent>
          <TimeControlMobile
            timeControlTypeName={timeControlTypeName}
            time={time}
            rating={rating}
            ratingType={ratingType}
          />
          {isTournament && <TournamentTypeAndRound gameData={gameData} />}
        </Card.HeaderContent>
        <Card.Divider />
      </>
    );
  }
);

import { NewGameCard } from '@chessarena/components/lib/active-games/NewGameCard';
import { NewGameCardType } from '@chessarena/components/lib/active-games/NewGameCard/types';
import { FC, memo } from 'react';
import { useNewGameData } from './hooks/useNewGameData.hook';

interface INewGameProps {
  type: NewGameCardType;
}

export const NewGame: FC<INewGameProps> = memo(function NewGame({ type }) {
  const { button, bubbleText } = useNewGameData();

  return <NewGameCard type={type} button={button} bubbleText={bubbleText} />;
});

export const getTitleFiles = ({ files, rotate, ratio, fontSize }) => {
    return files.map((item, i) => ({
        id: item,
        style: {
            position: "absolute",
            top: rotate ? "0" : "initial",
            bottom: rotate ? "initial" : "0",
            left: rotate ? i * ratio : "initial",
            right: rotate ? "initial" : (7 - i) * ratio,
            fontSize: `${fontSize}px`,
            lineHeight: 1,
            padding: "0.1em 0.2em",
            transform: `rotate(${rotate ? 180 : 0}deg)`,
        },
    }));
};

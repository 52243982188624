import { useMemo } from 'react';
import { chessSide } from 'chessgun/core';

import { getPlayersMsLeft } from '@utils/_ng_helpers';
import { useNGPageContext } from '../context';

const getPlayerTime = <T>({
  playerColor,
  whiteData,
  blackData,
}: {
  playerColor: chessSide;
  whiteData: T;
  blackData: T;
}): T => {
  return playerColor === chessSide.WHITE ? whiteData : blackData;
};

export function useGameTimers(): { whiteMsLeft: number; blackMsLeft: number };
export function useGameTimers(playerColor?: chessSide): number;

export function useGameTimers(playerColor?: chessSide) {
  const {
    state: { boardFlipped },
    computed: { boardData, currentGame },
  } = useNGPageContext();

  const msLeft = useMemo(() => {
    if (boardData?.result) {
      return {
        whiteMsLeft: boardData.white_ms_left,
        blackMsLeft: boardData.black_ms_left,
      };
    }

    const { whiteMsLeft, blackMsLeft } = getPlayersMsLeft(currentGame?.state);

    return {
      whiteMsLeft,
      blackMsLeft,
    };
  }, [currentGame?.state, boardData?.result, boardFlipped]); // recalculate after board flipped

  return playerColor
    ? getPlayerTime({
        playerColor,
        whiteData: msLeft.whiteMsLeft,
        blackData: msLeft.blackMsLeft,
      })
    : msLeft;
}

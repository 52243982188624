"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewGameCard = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const NewGameCardList_1 = require("./_internal/NewGameCardList");
const NewGameCardNotifications_1 = require("./_internal/NewGameCardNotifications");
exports.NewGameCard = (0, react_1.memo)(function NewGameCard(_a) {
    var { type } = _a, otherProps = __rest(_a, ["type"]);
    return ((0, jsx_runtime_1.jsxs)(jsx_runtime_1.Fragment, { children: [type === 'notifications' && (0, jsx_runtime_1.jsx)(NewGameCardNotifications_1.NewGameCardNotifications, Object.assign({}, otherProps)), type === 'list' && (0, jsx_runtime_1.jsx)(NewGameCardList_1.NewGameCardList, Object.assign({}, otherProps))] }));
});

import { useNGPageContext } from '@pages/game/[id]/context';
import { GameUserType } from '@types';

export const useIsPlayer = () => {
  const {
    computed: { gameUserType },
  } = useNGPageContext();

  return gameUserType === GameUserType.PLAYER;
};

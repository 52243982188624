import { ActiveGamesPopup } from '@chessarena/components/lib/active-games/ActiveGamesPopup';
import { ActiveGamesPopupLinkRenderLinkParams } from '@chessarena/components/lib/active-games/ActiveGamesPopup/types';
import { routesConstants } from '@constants';
import { useActiveGamesContext } from '@store/context/active-games/_context';
import { useApplicationContext } from '@application';
import Link from 'next/link';
import { FC, PropsWithChildren, memo, useCallback } from 'react';

interface IHeader {
  closePopup: () => void;
}

export const Header: FC<PropsWithChildren<IHeader>> = memo(function Header({
  closePopup,
}) {
  const {
    computed: { activeGames },
  } = useActiveGamesContext();

  const { localization: l } = useApplicationContext();

  const renderLink = useCallback(
    ({ link, children }: ActiveGamesPopupLinkRenderLinkParams) => {
      return (
        <Link type="secondary" href={link} prefetch={false}>
          {children}
        </Link>
      );
    },
    []
  );

  return (
    <ActiveGamesPopup.Header>
      <ActiveGamesPopup.HeaderText count={activeGames.length}>
        {l?.active_games?.active_games}
      </ActiveGamesPopup.HeaderText>
      <ActiveGamesPopup.HeaderLink
        label={l?.active_games?.all_my_games}
        link={routesConstants.ACTIVE_GAMES}
        renderLink={renderLink}
        onClick={closePopup}
      />
    </ActiveGamesPopup.Header>
  );
});

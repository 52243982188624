"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
const context_1 = require("./context");
const Popup = (props) => {
    const { opened = false, onOpen, onClose, closingOnOutClick = false, closingOnEscPress = false, closingOnOpenerClick = false, closingOnMouseLeave = false, children, } = props;
    const [opener, setOpener] = (0, react_1.useState)(null);
    const setOpenerNode = (node) => {
        setOpener(node);
    };
    const handleToggle = (0, react_1.useCallback)(() => {
        if (opened)
            onClose();
        if (!opened)
            onOpen();
    }, [onClose, onOpen, opened]);
    const context = (0, react_1.useMemo)(() => ({
        opened,
        opener,
        onOpen,
        onClose,
        onToggle: handleToggle,
        closingOnOutClick,
        closingOnEscPress,
        closingOnOpenerClick,
        closingOnMouseLeave,
        setOpenerNode,
    }), [
        opened,
        opener,
        onOpen,
        onClose,
        handleToggle,
        closingOnOutClick,
        closingOnEscPress,
        closingOnOpenerClick,
        closingOnMouseLeave,
    ]);
    return (0, jsx_runtime_1.jsx)(context_1.PopupContext.Provider, { value: context, children: children });
};
exports.default = Popup;

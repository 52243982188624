import Link from 'next/link';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { routesConstants } from '@constants';
import { useApplicationContext } from '@application';
import { headerSubMenu } from './_constants';
import {
  useDoesMatchArmageddonRoute,
  useDoesMatchBroadcastsRoute,
  useDoesMatchCommunityRoute,
  useDoesMatchLearnRoute,
} from './_useRouteMatch.hook';

export const useCustomHeaderSubMenu = () => {
  const router = useRouter();

  const doesMatchCommunityRoute = useDoesMatchCommunityRoute();
  const doesMatchBroadcastsRoute = useDoesMatchBroadcastsRoute();
  const doesMatchLearnRoute = useDoesMatchLearnRoute();
  const doesMatchArmageddonRoute = useDoesMatchArmageddonRoute();

  const { localization: l } = useApplicationContext();

  const items = useMemo(() => {
    let items;

    if (doesMatchBroadcastsRoute.value) items = headerSubMenu.broadcasts;
    else if (doesMatchArmageddonRoute.value) items = headerSubMenu.armageddon;

    if (!items) return [];

    return items.map((item) => {
      let active = false;

      if (item.link === router.asPath) active = true;

      if (
        !!router.asPath.match(new RegExp(`^${routesConstants.COMMUNITY}`)) &&
        item.id === 'clubs' &&
        router.asPath !== routesConstants.PLAYERS &&
        router.asPath !== routesConstants.CHESS_CLUBS_REVOLUTION &&
        !router.asPath.match(new RegExp(`^${routesConstants.PLAYERS_TOP}`))
      )
        active = true;

      if (
        !!router.asPath.match(new RegExp(`^${routesConstants.COMMUNITY}`)) &&
        item.id === 'ratings' &&
        !!router.asPath.match(new RegExp(`^${routesConstants.PLAYERS_TOP}`))
      )
        active = true;

      if (
        !!router.asPath.match(new RegExp(`^${item.link}`)) &&
        item.id === 'masterclasses'
      )
        active = true;

      return {
        ...item,
        title: l?.header?.sub_menu?.[item.id],
        link: item.link && (
          <Link href={item.link} prefetch={false}>
            {l?.header?.sub_menu?.[item.id]}
          </Link>
        ),
        active,
        current: item.link === router.asPath,
      };
    });
  }, [
    doesMatchCommunityRoute.value,
    doesMatchBroadcastsRoute.value,
    doesMatchLearnRoute,
    doesMatchArmageddonRoute.value,
    router.asPath,
    l?.header?.sub_menu,
  ]);

  const subMenu = useMemo(
    () => ({
      items,
    }),
    [items]
  );

  return {
    subMenu,
  };
};

import { useRouter } from 'next/router';

import { routesConstants } from '@constants';

export interface IRouteMatch {
  value: boolean;
  isExact: boolean;
}

// TODO: fix
// WIP
export const useMatch = (routes: string | string[]): IRouteMatch => {
  const { asPath, pathname } = useRouter();
  const route = pathname === '/_error' ? asPath.split('?')[0] : pathname;

  const isString = typeof routes === 'string';

  if (isString) {
    return {
      value: routes === route,
      isExact: routes === route,
    };
  }

  return {
    value: Boolean(routes.find((item) => route.includes(item))),
    isExact: Boolean(routes.find((item) => route === item)),
  };
};

export const useDoesMatchCommunityRoute = () =>
  useMatch([routesConstants.COMMUNITY]);

export const useDoesMatchBroadcastsRoute = () =>
  useMatch([routesConstants.BROADCAST_LOBBY]);

export const useDoesMatchLearnRoute = () => {
  const { pathname } = useRouter();

  const matched = useMatch([
    routesConstants.MASTERCLASSES,
    routesConstants.PUZZLES,
  ]);

  if (pathname === `${routesConstants.PUZZLES}/[id]`) {
    return {
      value: false,
      isExact: false,
    };
  }

  return matched;
};

export const useDoesMatchArmageddonRoute = () =>
  useMatch([
    routesConstants.ARMAGEDDON,
    routesConstants.ARMAGEDDON_FINALE,
    routesConstants.EMAIL_ARMAGEDDON,
  ]);

export const useDoesMatchCreateClubRoute = () =>
  useMatch(routesConstants.COMMUNITY_CREATE);

export const useDoesMatchRatingsRoute = () => useMatch(routesConstants.PLAYERS);

export const useDoesMatchArenaRoute = () => useMatch(routesConstants.HOME);

import { useEffect, useMemo } from 'react';
import { chessSide } from 'chessgun/core';

import { useCountdown } from '@utils/hooks/_useCountdown';

/**
 * Хук таймера первого хода игрока
 * @param {chessSide} playerColor - цвет игрока
 * @param {chessSide} playerTurn - текущий ход
 * @param {number} whitePreMoveMsLeft - время на первый ход для белых
 * @param {number} blackPreMoveMsLeft - время на первый ход для чёрных
 * @returns {number} milliseconds
 */
export const usePreMoveTime = ({
  playerColor,
  playerTurn,
  whitePreMoveMsLeft,
  blackPreMoveMsLeft,
}: {
  playerColor?: chessSide;
  playerTurn: chessSide;
  whitePreMoveMsLeft: number;
  blackPreMoveMsLeft: number;
}) => {
  const preMoveMsLeft = useMemo(() => {
    if (!playerColor) return 0;

    return playerColor === chessSide.WHITE
      ? whitePreMoveMsLeft
      : blackPreMoveMsLeft;
  }, [playerColor, whitePreMoveMsLeft, blackPreMoveMsLeft]);

  const { milliseconds, updateTimer, startTimer, stopTimer } = useCountdown({
    timeInMilliseconds: preMoveMsLeft,
    tickTime: 100,
  });

  useEffect(() => {
    if (playerTurn !== playerColor || !preMoveMsLeft) {
      if (milliseconds) {
        stopTimer();
        updateTimer(0);
      }

      return;
    }

    if (!milliseconds) {
      updateTimer(preMoveMsLeft);
    }

    startTimer();
  }, [preMoveMsLeft, playerTurn, playerColor]);

  return milliseconds;
};

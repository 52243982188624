import { useCallback, useState } from 'react';
import Router from 'next/router';
import { paymentService } from '@services/_payment.service';

export const useRedirectToCustomerPortal = () => {
  const [inRequest, setInRequest] = useState(false);

  const onCustomerPortalClick = useCallback(async () => {
    const pageUrl = `${window.location.origin}${Router.asPath}`;

    try {
      setInRequest(true);

      const { data, ok } = await paymentService.getCustomerPortalUrl({
        successUrl: pageUrl,
      });

      if (ok) {
        Router.push(data.customer_portal_url);
      }
    } catch (err) {
      console.log('get customer portal error', err);
    }

    // setInRequest(false);
  }, []);

  return { inRequest, onCustomerPortalClick };
};
